import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { IdToken } from '@auth0/auth0-spa-js';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { EMPTY } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { INotification, NotificationService } from './core/services/notification.service';
import { InfoServices } from './main/main.services';
import { updateProfile, updateStepper } from './main/store/actions';
import { Status } from './shared/constants';
import { ROLES } from './shared/constants/role';
import {
  Acknowledgement,
  Funfact, HighSchoolExperience, PersonalInfo,
  UserReviewInput,
  UserTimelineInput
} from './shared/models';
import { setAccountAction } from './store/actions/user-account.actions';
import { AppState } from './store/reducer';
import { fetchMedia, updateAllTimeline, updateApplicantReview } from './timeline/store/timeline.actions';

@Component({
  selector: 'vm-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'vm-portal';
  isLoading = true;
  displayErrorModal = false;
  errorMessage = '';

  constructor(
    public auth: AuthService,
    public store: Store<AppState>,
    private infoServices: InfoServices,
    private router: Router,
    private notiService: NotificationService,
    private messageService: MessageService
  ) {
    this.notiService.message$.subscribe(
      (message: INotification) => this.messageService.add(message)
    );
    this.auth.idTokenClaims$
      .pipe(
        filter(claims => !!claims),
        switchMap((claims: IdToken | null | undefined) => {
          this.isLoading = true;
          const token = claims ? claims.__raw : '';
          localStorage.setItem('token', token);
          const email = claims?.email ? claims.email : '';

          // return this.infoServices.setUserAccount(email);
          return this.infoServices.getUserInfoDt();
        }),
        switchMap(({ userId, email, roles, active }) => {
          const token = localStorage.getItem('token');
          if (this.router.url.includes('/profile/register')) {
            const params = { userId, email };
            this.store.dispatch(setAccountAction({ account: params }));
            this.isLoading = false;
            return EMPTY;
          }

          if (active && roles.includes(ROLES.GLOBAL_ADMIN)) {
            localStorage.removeItem('token');
            this.auth.logout({ returnTo: `${environment.ADMIN_URL}?token=${token}`});
            return EMPTY;
          } else if (
            active
            &&
            (roles.includes(ROLES.ORG_ADMIN) || roles.includes(ROLES.ORG_USER))
            && !(roles.includes(ROLES.MENTOR))
          ) {
            localStorage.removeItem('token');
            this.auth.logout({ returnTo: `${environment.ADMIN_URL}?token=${token}`});
            return EMPTY;
          } else if (active && roles.includes(ROLES.MENTOR) && (roles.includes(ROLES.ORG_ADMIN) || roles.includes(ROLES.ORG_USER))) {
            if (this.router.url.includes('switchView')) {
              this.router.navigate([ '/home' ]);
            } else if (this.router.url.includes('logout')) {
              this.auth.logout({ returnTo: document.location.origin });
            } else {
              // Redirect
              window.location.href = `${environment.ADMIN_URL}?token=${token}`;
              return EMPTY;
            }
          }
          const params = { userId, email };
          this.store.dispatch(setAccountAction({ account: params }));
          this.isLoading = false;
          return this.infoServices.getUserInfo(userId);
        })
      )
      .pipe(filter(account => !!account))
      .subscribe(userInfo => {
        const {
          firstName,
          acknowledgement: ack,
          funfact,
          timeline: tl,
          status,
          review: rv,
          applicantReviews,
          media, ...rest
        } = userInfo;
        const profile = new PersonalInfo({ ...rest, firstName });
        const funfacts = new Funfact(funfact);
        const acknowledgement = new Acknowledgement(ack);
        const review = new UserReviewInput(rv);
        const { highSchoolExperience: hs, jobExperience, additionalEducationExperience } = new UserTimelineInput(tl);
        const completed = [
          firstName ? 1 : 0,
          !!funfact ? 2 : 0,
          !!ack ? 3 : 0
        ].filter(x => Boolean(x));
        const eduList = additionalEducationExperience?.list || [];
        const isEducationCompleted = eduList?.length && !!(eduList[ eduList.length - 1 ].whyChoose);
        const completedTimeline = [
          hs?.topAdvise ? 1 : 0,
          isEducationCompleted ? 2 : 0,
          status?.timeline == Status.COMPLETED ? 3 : 0
        ].filter(x => Boolean(x));
        const highSchoolExperience = new HighSchoolExperience(hs);
        this.store.dispatch(updateProfile({ profile, funfacts, acknowledgement, status, review }));
        this.store.dispatch(updateAllTimeline({ highSchoolExperience, jobExperience, additionalEducationExperience, completedTimeline }));
        this.store.dispatch(updateStepper({ completed }));
        this.store.dispatch(fetchMedia({ media }));

        this.store.dispatch(updateApplicantReview({ applicantReview: applicantReviews.length ? applicantReviews[0] : {} }));
      }, err => {
        if (this.router.url.includes('/profile/register')) {
          this.isLoading = false;
        } else {
          console.log('error ', err);
          this.displayErrorModal = true;
          this.errorMessage = 'Oops! It looks like that email address has not been invited to join Virtual Mentor. Please login with the invitation link you received. If you have not received an invitation, please contact your Virtual Mentor Organization Admin for details';
        }
      });
  }

  ngOnInit() {
    this.auth.isAuthenticated$.subscribe(isAuth => {
      this.isLoading = isAuth;
    });
  }

  closeError() {
    this.auth.logout({ returnTo: document.location.origin });
    localStorage.removeItem('token');
  }
}

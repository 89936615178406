import { createReducer, on } from '@ngrx/store';
import { IActivity } from '../../shared/models';
import * as fromStatic from '../actions/static.actions';

export interface StaticState {
  activities: IActivity[];
};

export const initialState: StaticState = {
  activities: [],
};

export const reducer = createReducer(
  initialState,
  on(
    fromStatic.setActivityList,
    (state, { activities }) => ({ ...state, activities })
  ),
);

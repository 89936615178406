export class UserReviewInput
{
  userId!: string;
  status?: string;
  payload?: any
  constructor(init?: Partial<UserReviewInput>)
  {
    if (init)
    {
      Object.assign(this, init);
    }
  };
}

export class Acknowledgement
{
  whyMentor: string = '';
  hearAboutUs: string = '';
  acknowledgementAgreement: boolean = false;

  constructor(init?: Partial<Acknowledgement>)
  {
    if (init)
    {
      Object.assign(this, init);
    };
  }
}

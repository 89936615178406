import { createSelector } from '@ngrx/store';
import { selectProfile } from '../../../store/reducer';
import { ProfileState } from '../reducer/profile.reducer';

export const selectPersonalInfo = createSelector(
  selectProfile,
  (state: ProfileState) => state.info
);

export const selectFunfacts = createSelector(
  selectProfile,
  (state: ProfileState) => state.funfacts
);

export const selectAcknowledgement = createSelector(
  selectProfile,
  (state: ProfileState) => state.acknowledgement
);

export const selectPersonalInfoStepper = createSelector(
  selectProfile,
  (state: ProfileState) => state.stepper
);

export const selectUserStatus = createSelector(
  selectProfile,
  (state: ProfileState) => state.status
);

export interface UserQuizInput
{
  userId: string;
  quizId: number;
  answer: any;
}

export enum QuizType {
  PERSONALITY = 'PERSONALITY'
}

export interface UserQuizResult
{
  userId: string;
  quizId: number;
  result: {
    label: string;
    type: string;
    raisec: string;
    detail: string;
  };
  quiz: {
    type: QuizType
  }
}

export interface UserQuizResponseData {
  setUserQuiz: UserQuizResult
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { environment } from 'apps/vm-portal/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { resetInfo } from '../../main/store/actions/profile.actions';
import { selectPersonalInfo } from '../../main/store/selectors/profile.selectors';
import { ROLES } from '../../shared/constants/role';
import { PersonalInfo } from '../../shared/models';
import { AppState } from '../../store/reducer';
import { INavbar, NAV } from './navbar.constant';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.scss' ],
})
export class NavbarComponent implements OnInit {
  private destroyed$ = new Subject();
  inProduction?: boolean;
  isNavbarCollapsed = true;
  sidebarDisplay = false;
  openAPIEnabled?: boolean;
  version = '';
  avatarUrl = '';
  menu: INavbar[] = NAV;
  info$: Observable<PersonalInfo>;
  info!: PersonalInfo;
  displayModal = false;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    public store: Store<AppState>,
  ) {
    this.info$ = this.store.select(selectPersonalInfo);
  }

  ngOnInit() {
    this.info$
      .pipe(filter(info => !!info))
      .subscribe(info => {
        this.avatarUrl = info.bioPicture;
        this.info = info;
        if (info.roles.includes(ROLES.GLOBAL_ADMIN) && info.roles.includes(ROLES.MENTOR)) {
          this.displayModal = true;
        }
      });
  }

  has2Roles(): boolean {
    if (this.info) {
      const roles = this.info.roles;
      if (roles.includes(ROLES.MENTOR) && (roles.includes(ROLES.ORG_ADMIN) || roles.includes(ROLES.ORG_USER))) {
        return true;
      }
      return false;
    }
    return false;
  }

  logOut() {
    this.store.dispatch(resetInfo());
    this.auth.logout({ returnTo: document.location.origin });
  }

  gotoAdminSite() {
    const token = localStorage.getItem('token');
    window.location.href = `${environment.ADMIN_URL}?token=${token}`;
  }

  stayHere() {
    this.displayModal = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}

<div class="audio-caption" *ngIf="link">
  <div
    *ngIf="media?.captionUrl && media?.captionGoogle && captionTextList.length"
    class="audio-caption__container"
  >
    <ng-container
      *ngFor="let captionTextItem of captionTextList; let id = index"
    >
      <div
        [class.caption-activated]="captionTextItem?.activated"
        [id]="'captionTextItem-' + id + '-' + media?.mediaId"
      >
        <p>
          {{ captionTextItem?.word }}
        </p>
      </div>
    </ng-container>
  </div>

  <audio class="audio-caption__audio" controls crossorigin="anonymous">
    <source [src]="link" type="audio/mpeg" />
    <source [src]="link" type="audio/x-m4a" />
    <source [src]="link" type="audio/wav" />
    <track
      *ngIf="media?.captionUrl"
      (cuechange)="cuechangeTime($event)"
      [src]="media?.captionUrl"
      label="English"
      kind="subtitles"
      srclang="en"
      default
    />
    Your browser does not support the video tag.
  </audio>
</div>

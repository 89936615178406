import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: false,
  AUTH0_DOMAIN: 'dev-tmm2.us.auth0.com',
  AUTH0_CLIENT_ID: 'srdQZYvNvGqzl1Efd2pPELY2Xd7mYCwp',
  API_URL: 'https://tmm2-dev-api.watasolutions.app',
  ADMIN_URL: 'https://tmm2-dev-admin.watasolutions.app',
  TERM_OF_SERVICE:
    'https://tmm2-public-dev.s3.us-east-2.amazonaws.com/doc/Terms_of_Use-Mentor_Program.pdf',
};

export interface INavbar {
  title: string;
  icon: string;
  link: string;
}

export const NAV = [
  {
    title: 'Dashboard',
    icon: 'fas fa-border-all',
    link: '/profile/dashboard'
  },
];

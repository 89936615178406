import { gql } from 'apollo-angular';

// QUERY
export const infoQuery = gql`
  query getUserInfoById($userId: ID!) {
    getUserInfoById(userId: $userId) {
      userId,
      email,
      firstName,
      lastName,
      age,
      preferredPronouns,
      primaryLanguage,
      secondaryLanguage,
      categories,
      linkedinURL,
      ethnicity,
      zipCode,
      bio,
      roles,
      bioPicture,
      funfact{
        musicalArtists,
        foods,
        hobbies
      },
      acknowledgement {
        whyMentor,
        hearAboutUs,
        acknowledgementAgreement
      },
       status {
        profile
        assessment
        timeline
      },
      review {
        status
        payload
      },
      timeline {
        highSchoolExperience,
        additionalEducationExperience,
        jobExperience
      },
      quizzes {
        quizId,
        quiz,
        result
      },
      media {
        userId
        mediaId
        link
        captionGoogle
        captionUrl
        captionText
        captionEmbedded
        isInvalid
        type
        content
        filename
        isRecording
      },
      organizations {
        type
        organization {
          organizationId
          name
        }
      }
      applicantReviews {
        applicantReviewId
        applicantId
        timeline
        status
      }
    }
  }
`;

export const sendEmailConnectWithUsQuery = gql`
  query sendEmailConnectWithUs($input: SendEmailConnectWithUsInput!) {
    sendEmailConnectWithUs(input: $input)
  }
`;

export const infoUserQuery = gql`
  query getUserInfo {
    getUserInfo {
      userId,
      email,
      roles,
      active
    }
  }
`;

export const quizQuestionQuery = gql`
  query getUserQuizQuestion($userId: String!, $quizId: Int) {
    getUserQuizQuestion(input: {
      userId: $userId,
      quizId: $quizId
    }) {
      userId,
      quizId
      question
      addition
    }
  }
`;

export const getEducationLevelTypesQuery = gql`
  query getEducationLevelTypes {
    vpEducationLevelTypes(
      sorting: [
        {
          field: id,
          direction: ASC
        }
      ],
      filter: {
        id: {
          gte: 0
        }
      }
    ){
      id
      name
    }
  }
`;

export const aboutQuery = gql`
      query getHearAboutUs {
         getHearAboutUs {
          hearAboutUsId,
          content
        }
      }
    `;

export const activityListQuery = gql`
      query activityList {
         activityList {
          id,
          name
        }
      }
    `;

export const getQueryOccupationByOnetCode = gql`
      query occupationByOnetCode($onetCode: ID!) {
        occupationByOnetCode(onetCode: $onetCode) {
          onetTitle,
          onetCode,
          occupationKSAs {
            importance
            level
            ksa {
              id
              name
              description
            }
          }
        }
      }
    `;

export const getQuerySkillOnetByActivityId = gql`
      query activityById($id: ID!) {
        activityById(id: $id) {
          id
          onetKSAs {
            id
            name
            description
          }
        }
      }
    `;

export const skillsOnetKSAsQuery = gql`
  query onetKSAs {
    onetKSAs {
      id
      name
      description
    }
  }
`;

export const industriesQuery = gql`
      query industriesList {
         industriesList {
          id,
          name
        }
      }
    `;

export const ethnicityQuery = gql`
      query institutions ($filter: InstitutionFilter)  {
        institutions(filter: $filter) {
          name,
              ethnicityList{
              name
              type
              percent
            }
        }
      }
    `;

// MUTATION
export const quizMutation = gql`
      mutation setUserQuiz($input: UserQuizInput) {
        setUserQuiz(input: $input) {
          userId,
          quizId,
          result
        }
      }
    `;

export const infoMutation = gql`
      mutation setUserPersonalInfo($input: UserPersonalInfoInput) {
        setUserPersonalInfo(input: $input) {
          ethnicity,
          firstName,
          lastName
        }
      }
    `;

export const funfactMutation = gql`
      mutation setUserFunfact($input: UserFunfactInput) {
        setUserFunfact(input: $input) {
          userId,
          musicalArtists,
          foods,
          hobbies
        }
      }
    `;
export const acknowledgementMutation = gql`
      mutation setUserAcknowledgement($input: UserAcknowledgementInput) {
        setUserAcknowledgement(input: $input) {
          userId,
        }
      }
    `;

export const accountMutation = gql`
      mutation setUserAccount($input: UserAccountInput) {
        setUserAccount(input: $input) {
          userId,
          email,
          roles
        }
      }
    `;

export const userMediaQuery = gql`
      mutation setUserMedia($input: UserMediaInput) {
        setUserMedia(input: $input) {
          userId
          mediaId
          link
          captionGoogle
          captionUrl
          captionText
          captionEmbedded
          isInvalid
          type
          content
          filename,
          isRecording
        }
      }
    `;
export const userReviewMutation = gql`
      mutation setUserReview($input: UserReviewInput) {
        setUserReview(input: $input) {
          userId,
          status,
        }
      }
    `;

export const sendEmailSubmitTimelineMutation = gql`
      mutation sendEmailSubmitTimeline($input: SendEmailSubmitTimelineInput) {
        sendEmailSubmitTimeline(input: $input) {
          message
        }
      }
    `;


export const userStatusMutation = gql`
      mutation setUserStatus($input: UserStatusInput) {
        setUserStatus(input: $input) {
          userId,
          profile,
          assessment,
          timeline
        }
      }
    `;

export const userAdditionalInfoMutation = gql`
      mutation setUserAdditionalInfo($input: UserAdditionalInfoInput) {
        setUserAdditionalInfo(input: $input) {
          userId
        }
      }
    `;

export const userInfoMutation = gql`
      mutation setUserTimeline($input: UserTimelineInput) {
        setUserTimeline(input: $input) {
          userId,
          highSchoolExperience,
          additionalEducationExperience,
          jobExperience
        }
      }
    `;

export const createOrganizationAdmin = gql`
      mutation createOrganizationAdmin($input: CreateOrganizationAdminInput) {
        createOrganizationAdmin(input: $input) {
          userId,
		      email,
          roles
        }
      }
    `;

export const createOrganizationUser = gql`
      mutation createOrganizationUser($input: CreateOrganizationUserInput) {
        createOrganizationUser(input: $input) {
          userId,
		      email,
          roles
        }
      }
    `;


export const createMentor = gql`
      mutation createMentor($input: CreateMentorInput) {
        createMentor(input: $input) {
          userId,
		      email,
          roles
        }
      }
    `;

export const checkTokenExpireMutation =  gql`
      mutation checkTokenExpire($input: CheckTokenExpireInput) {
        checkTokenExpire(input: $input) {
          message
        }
      }
    `;

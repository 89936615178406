<nav class="container">
  <div class="topbar">
    <a href="#"
       class="topbar-logo">
      <img routerlink="/"
           alt="apollo-layout"
           src="assets/images/logo.png"
           class="logo">
    </a>
    <ng-container *ngIf="!(auth.isLoading$ | async)">
      <ng-container *ngIf="auth.isAuthenticated$ | async">
        <span class="profile desktop"
              (click)="profile.toggle($event)">
          <ng-container *ngIf="avatarUrl">
            <img routerlink="/"
                 class="avatar"
                 alt="avatar"
                 [src]="avatarUrl">
          </ng-container>
          <ng-container *ngIf="!avatarUrl">
            <em class='far fa-user-circle'></em>
          </ng-container>
          <em class="fa fa-chevron-down"></em>
        </span>
        <span class="profile mobile"
              (click)="sidebarDisplay = true">
          <ng-container *ngIf="avatarUrl">
            <img routerlink="/"
                 class="avatar"
                 alt="avatar"
                 [src]="avatarUrl">
          </ng-container>
          <ng-container *ngIf="!avatarUrl">
            <em class='far fa-user-circle'></em>
          </ng-container>
          <em class="fa fa-chevron-down"></em>
        </span>
      </ng-container>
      <ng-container *ngIf="!(auth.isAuthenticated$ | async)">
        <button
          class="logout"
          (click)="auth.loginWithRedirect({ redirectMethod: 'replace' })"
        >
          Sign in
        </button>
      </ng-container>
      </ng-container>
      <ng-container *ngIf="auth.isLoading$ | async">
        <vm-loading class="loading"></vm-loading>
    </ng-container>
  </div>
  <p-dialog
    header="TMM page"
    [(visible)]="displayModal"
    [modal]="true"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="block">
      <button
        (click)="this.gotoAdminSite()"
        pButton type="button" label="Go to admin site" ></button>
      <button
        (click)="this.stayHere()"
        pButton type="button" label="Stay here" ></button>
    </div>
  </p-dialog>

  <p-overlayPanel #profile
                  [styleClass]="'profile-popup'">
    <div *ngIf="has2Roles()" class="view view-mentor">
      <em class="fa-regular fa-square-user"></em> Mentor View
    </div>
    <ul>
      <li *ngFor="let nav of menu"
          [routerLink]="nav.link">
        <em [class]="'fa ' + nav.icon"></em>
        {{nav.title}}
      </li>
      <li (click)="logOut()"> <em class="fa fa-sign-out"></em> Log out </li>
    </ul>
    <p-divider *ngIf="has2Roles()"></p-divider>
    <div *ngIf="has2Roles()" class="view view-admin" (click)="gotoAdminSite()">
      <em class="far fa-user-cog"></em> Admin View
    </div>
    <p *ngIf="auth.user$ | async as user"
       class="placeholder">{{ user.email }}</p>
  </p-overlayPanel>
  <p-sidebar [(visible)]="sidebarDisplay"
             [styleClass]="'sidebar-popup'">
    <div *ngIf="has2Roles()" class="view view-mentor">
      <em class="fa-regular fa-square-user"></em> Mentor View
    </div>
    <ul class="sidebar-menu">
      <li *ngFor="let nav of menu"
          [routerLink]="nav.link">
        <em [class]="'fas ' + nav.icon"></em>
        {{nav.title}}
      </li>
    <li (click)="logOut()"><em class="fa fa-sign-out"></em> Log out </li>
    </ul>
    <p-divider *ngIf="has2Roles()"></p-divider>
    <div *ngIf="has2Roles()" class="view view-admin">
      <em class="far fa-user-cog"></em> Admin View
    </div>
    <p *ngIf="auth.user$ | async as user"
       class="placeholder">{{ user.email }}</p>
  </p-sidebar>
</nav>

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

enum NotiType {
  success = 'success',
  warn = 'warn',
  error = 'error'
}
export interface INotification {
  severity: NotiType,
  summary: string;
  detail: string;
}
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  message$: ReplaySubject<INotification> = new ReplaySubject(undefined);

  success(message: {title: string, detail: string;}) {
    this.message$.next({
      severity: NotiType.success,
      summary: message.title,
      detail: message.detail
    });
  }
  warn(message: {title: string, detail: string;}) {
    this.message$.next({
      severity: NotiType.warn,
      summary: message.title,
      detail: message.detail
    });
  }
  error(message: {title: string, detail: string;}) {
    this.message$.next({
      severity: NotiType.error,
      summary: message.title,
      detail: message.detail
    });
  }
}

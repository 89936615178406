import {createReducer, on} from '@ngrx/store';
import {IAccount} from '../../shared/models';
import * as fromUserAccount from '../actions/user-account.actions';

export interface UserState
{
  account: IAccount;
  isAuthenticated: boolean;
};

export const initialState: UserState = {
  account: null,
  isAuthenticated: false,
};

export const reducer = createReducer(
  initialState,
  on(
    fromUserAccount.getAccountAction,
    (state) => ({ ...state })
  ),
  on(
    fromUserAccount.setAccountAction,
    (state, { account }) => ({ ...state, account, isAuthenticated: true })
  ),
  on(
    fromUserAccount.resetAccountAction,
    (state) => ({ ...state, account: null, isAuthenticated: false })
  ),
);

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../core/services/notification.service';
import { InfoServices } from '../../../main/main.services';
import { AppState, selectUserAccount } from '../../../store/reducer';
import { initVideoAudio, MediaType } from '../../constants';
import { fillRange } from '../../helpers';
import { IFile } from '../../models';
import { IConstraint } from '../../models/media.model';
declare var MediaRecorder: any;

@Component({
  selector: 'record',
  templateUrl: './record.component.html',
  styleUrls: [ './record.component.scss' ]
})
export class RecordComponent implements OnInit {
  private completion$ = new Subject();
  account$: Observable<any>;
  account: any;
  @Input('textStart') textStart: string = "";
  @Input('textStop') textStop: string = "Stop";
  @Input('typeRecord') typeRecord: MediaType = MediaType.video;
  @Input('typeUpload') typeUpload: string = "";
  @Input('disabled') disabled: boolean = false;
  _file!: IFile;
  get file(): IFile {
    return this._file;
  }
  @Input('file') set media(value: IFile) {
    this._file = value;
    this.fileUploaded = value;
  }

  @Output() data: EventEmitter<any> = new EventEmitter<any>();

  mediaType = MediaType;
  constraints!: IConstraint;
  theStream: any;
  theRecorder: any;
  recordedChunks: any = [];
  isRecording = false;
  uploading = false;
  fileUploaded!: IFile;
  displayModal: boolean = false;
  displayRecordModal: boolean = false;
  numbers: any;

  constructor(public store: Store<AppState>, private infoServices: InfoServices, private notiService: NotificationService) {
    this.numbers = fillRange(1, 40);
    this.account$ = this.store.select(selectUserAccount);
  }

  ngOnInit() {
    combineLatest([
      this.account$
    ]).pipe(
      takeUntil(this.completion$)
    ).subscribe(data => {
      this.account = data[ 0 ];
    });
    this.isRecordVideo();
    this.fileUploaded = this.file;
  }

  isRecordVideo() {
    return this.typeRecord === MediaType.video;
  }

  startFunction() {
    this.theRecorder = null;
    this.theStream = null;
    this.recordedChunks = [];
    this.displayRecordModal = true;
    this.constraints = this.isRecordVideo() ? { "video": true, "audio": true } : { "audio": true };
    this.isRecording = true;
    navigator.mediaDevices.getUserMedia(this.constraints)
      .then(data => this.gotMedia(data))
      .catch(e => { console.error('getUserMedia() failed: ' + e); });
  }

  gotMedia(stream: any) {
    this.theStream = stream;
    let video = document.querySelector('video');
    let recorder;
    if (video) {
      video.srcObject = stream;
    }
    try {
      const mime = `${ this.typeRecord }/webm`;
      const mimeType = MediaRecorder.isTypeSupported(mime) ? mime : 'video/mp4';
      recorder = new MediaRecorder(stream, { mimeType });
    } catch (e) {
      console.error('Exception while creating MediaRecorder: ' + e);
    }
    this.theRecorder = recorder;
    recorder.ondataavailable =
      (event: any) => { this.recordedChunks.push(event.data); };
    recorder.start(100);
  }

  stop() {
    this.theRecorder.stop();
    this.theStream.getTracks().forEach((track: any) => { track.stop(); });

    const name = this.isRecordVideo() ? 'video.mp4' : 'audio.mp3';
    const type = this.isRecordVideo() ? 'video/mp4' : 'audio/mpeg';
    const blob = new Blob(this.recordedChunks, { type });
    const file = new File([ blob ], name, { type });
    this.uploading = true;
    this.upload(file);
    this.isRecording = false;
  }

  onUploadFile(event: Event): void {
    const target = <HTMLInputElement>event.target;
    if (target?.files?.[ 0 ]) {
      this.uploading = true;
      const file = target.files[ 0 ];
      this.upload(file);
    }
  }

  upload(file: File) {
    this.infoServices.uploadFile(file, this.typeUpload, true).subscribe(
      ({ data }: any) => {
        if (data) {
          const mapdata = this.typeRecord === MediaType.audio ? data.uploadAudio : data.uploadVideo;
          this.uploading = false;
          this.fileUploaded = { ...mapdata, type: this.typeRecord };
          this.data.emit({ ...mapdata, type: this.typeRecord, isRecording: true });
          this.notiService.success({
            title: 'Success',
            detail: `Uploaded ${ this.typeRecord }`,
          });
        } else {
          this.uploading = false;
          this.notiService.error({
            title: 'Fail',
            detail: 'Upload failed, please try again',
          });
        }
      }
    );
  }

  review() {
    this.displayModal = true;
  }

  stopRecording() {
    this.stop();
  }

  closeRecordPopup() {
    this.displayRecordModal = false;
  }

  removeFile() {
    const { mediaId, userId } = this.fileUploaded;
    this.fileUploaded = initVideoAudio(userId, mediaId);
    this.data.emit({ ...this.fileUploaded, type: this.typeRecord, isRecording: true });
  }
}

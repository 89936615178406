<p-dialog
  [header]="headerText"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="close()"
>
  <span>{{ content }}</span>

  <ng-template pTemplate="footer">
    <button
      pButton
      [label]="closeLabel"
      class="p-button-secondary"
      (click)="close()"
    ></button>
  </ng-template>
</p-dialog>

import { CollegeDetail, CollegeExperience } from '../models';
import { JobDetail, JobExperience } from '../models/job.model';

export const populateDegrees = (degrees: CollegeExperience, mediaList: any) => {
  const newDegrees = degrees.list.map((degree: CollegeDetail) => {
    const { activities } = degree;
    const newActivities = activities?.map((act: any) => {
      return {
        ...act,
        ...!!act?.media && { media: mediaList[ act.media.mediaId ] }
      };
    });
    return {
      ...degree,
      ...!!degree.topAdvise?.mediaId && { topAdvise: mediaList[ degree.topAdvise.mediaId ] },
      ...!!degree.whyChoose?.mediaId && { whyChoose: mediaList[ degree.whyChoose.mediaId ] },
      ...!!degree.activities?.length && { activities: newActivities }
    };
  });

  return { ...degrees, list: newDegrees };
};


export const populateJobs = (jobs: JobExperience, mediaList: any) => {
  const newjobExperience = jobs.list.map((job: JobDetail) => {
    return {
      ...job,
      ...!!job.media?.mediaId && { media: mediaList[ job.media.mediaId ] },
      ...!!job.advice?.mediaId && { advice: mediaList[ job.advice.mediaId ] },
      ...!!job.likeMost?.mediaId && { likeMost: mediaList[ job.likeMost.mediaId ] },
    };
  });

  return { ...jobs, list: newjobExperience };
};

export class UserStatusInput
{
  userId!: string;
  profile?: string;
  assessment?: string;
  timeline?: string;
  constructor(init?: Partial<UserStatusInput>)
  {
    if (init)
    {
      Object.assign(this, init);
    }
  };
}

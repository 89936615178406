import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { NavbarComponent } from '../layouts/navbar/navbar.component';
import { AudioCaptionComponent } from './component/audio-caption/audio-caption.component';
import { LoadingComponent } from './component/loading/loading.component';
import { MediaComponent } from './component/media/media.component';
import { RecordComponent } from './component/record/record.component';
import { UploadComponent } from './component/upload/upload.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { InfoComponent } from './info/info.component';

const PRIME_LIBRARY = [
  BadgeModule,
  CardModule,
  ButtonModule,
  OverlayPanelModule,
  InputTextModule,
  SidebarModule,
  FileUploadModule,
  MultiSelectModule,
  RadioButtonModule,
  ToastModule,
  TooltipModule,
  DropdownModule,
  BreadcrumbModule,
  InputTextareaModule,
  ProgressSpinnerModule,
  CheckboxModule,
  SliderModule,
  DialogModule,
  AutoCompleteModule,
  DividerModule
];
@NgModule({
  imports: [ CommonModule, FormsModule, ReactiveFormsModule, ...PRIME_LIBRARY ],
  declarations: [
    NavbarComponent,
    RecordComponent,
    UploadComponent,
    AudioCaptionComponent,
    MediaComponent,
    LoadingComponent,
    InfoComponent,
    DragDropDirective
  ],
  exports: [
    NavbarComponent,
    RecordComponent,
    UploadComponent,
    InfoComponent,
    AudioCaptionComponent,
    MediaComponent,
    LoadingComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ...PRIME_LIBRARY,
    DragDropDirective
  ]
})
export class SharedModule { }

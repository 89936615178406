
export const initVideoAudio = (userId: string, mediaId: string) => {
  return {
    url: "",
    captionGoogle: {},
    captionUrl: "",
    captionText: "",
    captionEmbedded: "",
    isInvalid: false,
    filename: "",
    mimetype: "",
    encoding: "",
    mediaId,
    userId
  };
};

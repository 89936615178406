import { createReducer, on } from '@ngrx/store';
import { IMediaList, Media, Nullable } from '../../shared/models';
import * as fromTimeline from './timeline.actions';


export interface MediaState {
  list: Nullable<IMediaList>
};

export const initialState: MediaState = {
  list: null
};

export const reducer = createReducer(
  initialState,
  on(
    fromTimeline.fetchMedia,
    (state, { media }) => {
      const dictionary = Object.assign({}, ...media.map((mediaItem: Media) => {
        const { __typename, ...newMedia } = mediaItem;
        return { [ mediaItem.mediaId ]: newMedia };
      }));
      return {
        ...state,
        list: dictionary
      };
    }
  ),
  on(
    fromTimeline.updateMediaItemInList,
    (state, { media }) => {
      const newList = { ...state.list };
      const { __typename, ...mapMedia } = media;
      newList[ media.mediaId ] = mapMedia;
      return {
        ...state,
        list: newList
      };
    }
  )

);

<p-toast></p-toast>
<ng-container *ngIf="!isLoading; else loading">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #loading>
  <div class="splash-screen">
    <div class="splash-loader"></div>
  </div>
  <div class="custom-dialog-confirm custom-dialog-info">
    <vm-info
      [displayModal]="displayErrorModal"
      [content]="errorMessage"
      (eventEmitter)="closeError()"
    ></vm-info>
  </div>
</ng-template>

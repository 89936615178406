import { Component, Input, OnInit } from '@angular/core';
import { IFile } from '../../models';

interface CaptionText {
  word: string;
  startTime: number;
  endTime: number;
  activated: boolean;
}

interface SentenceFullWord {
  word: string;
  startTime: any;
  endTime: any;
}

@Component({
  selector: 'audio-caption',
  templateUrl: './audio-caption.component.html',
  styleUrls: ['./audio-caption.component.scss'],
})
export class AudioCaptionComponent implements OnInit {
  @Input('media') media!: IFile;
  @Input('link') link: string | undefined;
  captionTextList: CaptionText[] = [];

  constructor() {}

  ngOnInit() {
    this.captionTextList = this.convertCaptionGoogleToCaptionTextList(
      this.media?.captionGoogle
    );
  }

  convertCaptionGoogleToCaptionTextList(
    captionGoogle: any
  ): CaptionText[] | [] {
    if (captionGoogle.length) {
      try {
        let groupSentences = this.transformToGroupSentences(captionGoogle);
        let sentenceFullWords = this.transformToSentenceFullWords(
          groupSentences
        );
        return this.transformToSubtitles(sentenceFullWords);
      } catch (error) {
        console.error('convertCaptionGoogleToCaptionTextList', error);
        return [];
      }
    } else {
      return [];
    }
  }

  transformToGroupSentences(captionGoogle: any) {
    const groupSentences: any[] = [];
    captionGoogle.forEach((result: any) => {
      const words = result.alternatives[0].words;
      let indexTemp = 0;
      for (let index = 0; index < words.length; index++) {
        if (
          words[index].word.includes('.') ||
          (index === words.length - 1 && !words[index].word.includes('.'))
        ) {
          const groupASentence = words.slice(indexTemp, index + 1);

          // more than 30 word in a sentence
          if (groupASentence.length > 30) {
            const groupASentenceFirst = groupASentence.slice(0, 30);
            const groupASentenceSecond = groupASentence.slice(
              30,
              groupASentence.length + 1
            );
            groupSentences.push(groupASentenceFirst, groupASentenceSecond);
          } else {
            groupSentences.push(groupASentence);
          }

          indexTemp = index + 1;
        }
      }
    });

    return groupSentences;
  }

  transformToSentenceFullWords(groupSentences: any): SentenceFullWord[] | [] {
    let sentenceFullWord: SentenceFullWord[] = [];
    groupSentences.forEach((groupASentence: any, indexCaption: number) => {
      sentenceFullWord[indexCaption] = {
        startTime: '',
        endTime: '',
        word: '',
      };
      sentenceFullWord[indexCaption].startTime = groupASentence[0].startTime;
      sentenceFullWord[indexCaption].endTime =
        groupASentence[groupASentence.length - 1].endTime;

      for (let index = 0; index < groupASentence.length; index++) {
        sentenceFullWord[indexCaption].word = sentenceFullWord[
          indexCaption
        ].word.concat(' ', groupASentence[index].word);
      }
    });

    return sentenceFullWord;
  }

  transformToSubtitles(
    sentenceFullWords: SentenceFullWord[] | []
  ): CaptionText[] | [] {
    let subtitles: CaptionText[] = [];
    sentenceFullWords.forEach((wordInfo: any, index: number) => {
      // NOTE: If you have a time offset exceeding 2^32 seconds, use the
      // wordInfo.{x}Time.seconds.high to calculate seconds.

      const nanosStart = wordInfo.startTime?.nanos
        ? wordInfo.startTime.nanos / 1000000000
        : 0.0;
      const secondsStart = wordInfo.startTime?.seconds
        ? +wordInfo.startTime.seconds
        : 0;
      const startFullTime = secondsStart + nanosStart;

      const nanosEnd = wordInfo.endTime?.nanos
        ? wordInfo.endTime.nanos / 1000000000
        : 0.0;
      const secondsEnd = wordInfo.endTime?.seconds
        ? +wordInfo.endTime.seconds
        : 0;
      const endFullTime = secondsEnd + nanosEnd;

      subtitles.push({
        word: wordInfo.word,
        startTime: startFullTime,
        endTime: endFullTime,
        activated: index === 0,
      });
    });

    return subtitles;
  }

  cuechangeTime(value: any) {
    let activeCue = value?.currentTarget?.track?.activeCues[0];
    if (activeCue && this.captionTextList.length) {
      let startTime = activeCue.startTime;
      let endTime = activeCue.endTime;
      let word = activeCue.text;
      let id = +activeCue.id;

      this.captionTextList = this.captionTextList.map(
        (item: CaptionText, index: number) => {
          item.activated =
            item.startTime === startTime &&
            item.endTime === endTime &&
            index === id;

          return item;
        }
      );

      const captionTextItemElement: any = document.getElementById(
        'captionTextItem-' + id + '-' + this.media?.mediaId
      );
      captionTextItemElement.parentNode.scrollTop =
        captionTextItemElement.offsetTop -
        captionTextItemElement.parentNode.offsetTop -
        50;
    }
  }
}

<div class="container p-component timeline-content highschool">
  <div class="container-form">
    <form [formGroup]="mediaForm">
      <div class="career p-grid p-d-flex">
        <div
          class="p-col-12 p-lg-4 p-order-lg-1 wrapper-option"
          (click)="checkOption(options[0])"
        >
          <div class="option" [ngClass]="{ active: options[0].active }">
            <div class="checkbox">
              <span *ngIf="!options[0].active"></span>
              <i *ngIf="options[0].active" class="fa fa-check-square"></i>
            </div>
            <img
              alt="Text Image"
              src="/assets/images/timeline/vm_keyboard.svg"
            />
            <p>{{ options[0].title }}</p>
          </div>
        </div>
        <div
          class="p-col-12 p-lg-4 p-order-lg-2 wrapper-option"
          [ngClass]="{
            'p-order-3': options[0].active
          }"
          (click)="checkOption(options[1])"
        >
          <div
            class="option"
            [ngClass]="{
              active: options[1].active
            }"
          >
            <div class="checkbox">
              <span *ngIf="!options[1].active"></span>
              <i *ngIf="options[1].active" class="fa fa-check-square"></i>
            </div>
            <img alt="Video Image" src="/assets/images/timeline/vm_video.svg" />
            <p>{{ options[1].title }}</p>
          </div>
        </div>
        <div
          class="p-col-12 p-lg-4 p-order-lg-3 wrapper-option"
          [ngClass]="{
            'p-order-4': options[0].active || options[1].active
          }"
          (click)="checkOption(options[2])"
        >
          <div
            class="option"
            [ngClass]="{
              active: options[2].active
            }"
          >
            <div class="checkbox">
              <span *ngIf="!options[2].active"></span>
              <i *ngIf="options[2].active" class="fa fa-check-square"></i>
            </div>
            <img alt="Audio Image" src="/assets/images/timeline/vm_audio.svg" />
            <p>{{ options[2].title }}</p>
          </div>
        </div>

        <!-- Text -->
        <div
          *ngIf="options[0].active"
          class="p-col-12 text-input p-order-2 p-order-lg-4"
        >
          <textarea
            pInputTextarea
            formControlName="text"
            [rows]="5"
            (input)="handleChange($event)"
            (focusout)="handleFocusOut($event)"
          ></textarea>
          <div class="p-d-flex p-jc-between">
            <span class="note">Max 500 characters</span>
            <span class="note">{{ this.mediaForm.get('text')?.value.length }} / 500</span>
          </div>
          <span *ngIf="this.mediaForm.get('text')?.errors?.maxlength && this.mediaForm.get('text')?.touched;"
                    class="form-message error">Value must be less than 500 characters</span>
        </div>

        <!-- Video -->
        <div
          *ngIf="options[1].active"
          class="p-col-12 upload-video p-order-3 p-order-lg-4 p-d-flex p-flex-column p-flex-lg-row p-jc-between p-nogutter"
        >
          <!-- Upload Video -->
          <div
            class="upload p-lg-12 p-col-12 p-d-flex p-flex-column p-jc-between p-as-lg-stretch p-as-center"
            vmDragDrop (fileDropped)="onFileDropped($event, mediaType.video)"
          >
            <div>
              <p class="upload-video-title">Upload Video</p>
              <p class="upload-video-icon">
                <img
                  alt="Upload Video Image"
                  src="/assets/images/timeline/vm-upload-video.svg"
                />
              </p>
              <p class="upload-video-explanation">
                Drag and drop video on this area
              </p>
            </div>
            <upload
              [type]="mediaType.video"
              [typeUpload]="'uploadVideo'"
              [extension]="' .mov, .mp4'"
              [textButton]="'Upload Video'"
              [disabled]="recordVideo?.filename !== ''"
              [file]="video"
              (data)="handleUploadAndRecord($event)"
              [fileEvent]="videoFileEvent"
              [fileMaxSizes]="200"
              (droppedFileEvent)="nullifyDroppedFile()"
            ></upload>
          </div>
        </div>

        <!-- Audio -->
        <div
          *ngIf="options[2].active"
          class="p-col-12 upload-video p-order-4 p-d-flex p-flex-column p-flex-lg-row p-jc-between p-nogutter"
        >
          <!-- Upload Audio -->
          <div
            class="upload p-lg-12 p-col-12 p-d-flex p-flex-column p-jc-between p-as-lg-stretch p-as-center"
            vmDragDrop (fileDropped)="onFileDropped($event, mediaType.audio)"
          >
            <div>
              <p class="upload-video-title">Upload Audio</p>
              <p class="upload-video-icon">
                <img
                  alt="Upload Audio Image"
                  src="/assets/images/timeline/vm_upload-audio.svg"
                />
              </p>
              <p class="upload-video-explanation">
                Drag and drop audio on this area
              </p>
            </div>
            <upload
              [type]="mediaType.audio"
              [typeUpload]="'uploadAudio'"
              [extension]="' .wav, .mp3'"
              [textButton]="'Upload Audio'"
              [disabled]="recordAudio?.filename !== ''"
              [file]="audio"
              (data)="handleUploadAndRecord($event)"
              [fileEvent]="audioFileEvent"
              [fileMaxSizes]="50"
              (droppedFileEvent)="nullifyDroppedFile()"
            ></upload>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

import { ROUTE } from './route.path';

export const PROFILE_BREADCRUMB = [ { label: 'Dashboard', routerLink: [ ROUTE.DASHBOARD ] }, { label: 'Create Your Profile' } ];

export const TIMELINE_BREADCRUMB = [ { label: 'Dashboard', routerLink: [ ROUTE.DASHBOARD ] }, { label: 'Build your timeline' } ];

export const ASSESSMENT_BREADCRUMB = [
  { label: 'Dashboard', routerLink: [ ROUTE.DASHBOARD ] },
  { label: 'Assessment' }
];

export const ASSESSMENT_PERSONAL_QUIZ_BREADCRUMB = [
  { label: 'Dashboard', routerLink: [ ROUTE.DASHBOARD ] },
  { label: 'Assessment', routerLink: [ ROUTE.ASSESSMENTS ] },
  { label: 'Personality quiz' }
];

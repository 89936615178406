export interface ITLStep {
  class: string;
  icon: string;
  title: string;
  highlight: boolean;
  completed: boolean;
  link?: string;
}

export enum TLClass {
  SCHOOL = 'highschool',
  COLLEGE = 'college',
  JOB = 'job',
  SUMMARY = 'summary'
}

export const TIMELINE_STEPS: ITLStep[] = [
  { class: TLClass.SCHOOL, icon: 'fa-school', title: 'High School / GED', link: 'highschool-step1', highlight: true, completed: false },
  { class: TLClass.COLLEGE, icon: 'fa-university', title: 'Additional Education', link: 'college-step1', highlight: false, completed: false },
  { class: TLClass.JOB, icon: 'fa-briefcase', title: 'Job Experience', link: 'job-step1', highlight: false, completed: false }
];

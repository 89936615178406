<p
  *ngIf="fileUploaded?.filename"
  class="file-wrapper"
>
  <button type="button" class="file-remove" (click)="removeFile()">
    <span class="file-remove-x">x</span>
  </button>
  <span class="file-name" (click)="review()">{{ fileUploaded.filename }}</span>
</p>
<button *ngIf="!isRecording" [disabled]="disabled" (click)="startFunction()">
  {{ textStart }}
</button>

<p-dialog [header]="'Record ' + typeRecord"
          [(visible)]="displayRecordModal"
          [modal]="true"
          [maximizable]="true"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false"
          (onHide)="stopRecording()">
          <div *ngIf="displayRecordModal" class="modal-container">
            <video *ngIf="typeRecord == mediaType.video" id="video" autoplay style="width: 100%; max-height: 60vh; margin-bottom: 20px"></video>
            <div *ngIf="typeRecord == mediaType.audio" id="video" class="bars">
              <div *ngFor="let item of numbers" class="bar"></div>
            </div>
            <button *ngIf="isRecording" (click)="closeRecordPopup()">{{ textStop }}</button>
          </div>
</p-dialog>
<p-dialog [header]="'Review ' + typeRecord"
          [(visible)]="displayModal"
          [modal]="true"
          [maximizable]="true"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false">
          <div *ngIf="displayModal">
            <audio *ngIf="fileUploaded?.filename && typeRecord == mediaType.audio"
              controls style="width: 100%;">
                <source [src]="fileUploaded?.url" type="audio/mpeg">
                <source [src]="fileUploaded?.url" type="audio/x-m4a">
                <source [src]="fileUploaded?.url" type="audio/wav">
                Your browser does not support the <code>audio</code> element.
            </audio>
            <video controls style="width: 100%; max-height: 70vh;" *ngIf="fileUploaded?.filename && typeRecord == mediaType.video">
              <source [src]="fileUploaded?.url" type="video/mp4">
              <source [src]="fileUploaded?.url" type="video/mov">
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
</p-dialog>

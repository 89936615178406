import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'vm-info',
  templateUrl: './info.component.html',
  styleUrls: [ './info.component.scss' ],
})
export class InfoComponent implements OnInit {
  @Input() displayModal: boolean = false;
  @Input() headerText = '';
  @Input() content = '';
  @Input() closeLabel = 'Close';

  @Output() eventEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.eventEmitter.emit();
  }
}

import { createAction, props } from "@ngrx/store";
import {UserQuizResult} from '../../../shared/models';
const prefix = 'Assessment';

export const saveQuizResult = createAction(
  `[${ prefix }] Save quiz result`,
  props<{
    userQuizResult: UserQuizResult;
  }>()
);

import { IMedia } from './user-media';

export enum StatusForReview {
  NONE = "NONE",
  INVALID = "INVALID",
  PENDING = "PENDING",
  INREVIEW = "INREVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface IReviewPayload {
  mediaId: string
  claim: string
  isResolved: boolean
}

export interface IReview {
  mediaId?: string
  status?: StatusForReview
  payload?: IReviewPayload[]
}

export const STATUS_FOR_REVIEW = {
  NONE: StatusForReview.NONE,
  INVALID: StatusForReview.INVALID,
  PENDING: StatusForReview.PENDING,
  INREVIEW: StatusForReview.INREVIEW,
  APPROVED: StatusForReview.APPROVED,
  REJECTED: StatusForReview.REJECTED
};

export interface IPropsMediaReup extends IReviewPayload{
  media:  IMedia,
  review: IReview,
  callback?: any
}

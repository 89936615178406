import {
  ActionReducerMap,
  createSelector,
  MetaReducer
} from "@ngrx/store";
import * as fromProfile from "./../../main/store/reducer/profile.reducer";
import * as fromMedia from "./../../timeline/store/media.reducer";
import * as fromTimeNavigationline from "./../../timeline/store/timeline-navigate.reducer";
import * as fromTimeline from "./../../timeline/store/timeline.reducer";
import * as fromStatic from "./static.reducer";
import * as fromUser from "./user-account.reducer";

export interface AppState {
  account: fromUser.UserState;
  profile: fromProfile.ProfileState;
  timeline: fromTimeline.TimelineState;
  media: fromMedia.MediaState;
  static: fromStatic.StaticState;
  timelineNavigation: fromTimeNavigationline.TimelineNavigationState
}

export const reducers: ActionReducerMap<AppState> = {
  account: fromUser.reducer,
  profile: fromProfile.reducer,
  timeline: fromTimeline.reducer,
  media: fromMedia.reducer,
  static: fromStatic.reducer,
  timelineNavigation: fromTimeNavigationline.reducer
};

export const metaReducers: MetaReducer<AppState>[] = [];

export const selectApp = (state: AppState) => state.account;
export const selectProfile = (state: AppState) => state.profile;
export const selectTimeline = (state: AppState) => state.timeline;
export const selectUserMediaList = (state: AppState) => state.media;
export const selectStatic = (state: AppState) => state.static;
export const selectTimelineNavigation = (state: AppState) => state.timelineNavigation;

export const selectUserAccount = createSelector(
  selectApp,
  (state: fromUser.UserState) => state.account
);

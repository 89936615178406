<p class="upload-video-note">
  <span class="upload-video-note-normal">Acceptable file type:</span>
  <strong class="upload-video-note-bold"> {{ extension }}</strong>
</p>
<p *ngIf="fileUploaded?.filename" class="file-wrapper">
  <button type="button" class="file-remove" (click)="removeFile()">
    <span class="file-remove-x">x</span>
  </button>
  <span class="file-name" (click)="review()">{{ fileUploaded.filename }}</span>
</p>
<div class="grid">
  <button
    type="file"
    class="file-upload"
    [disabled]="uploading || disabled"
    (click)="uploadFile.click()"
  >
    {{ textButton }}
    <p-progressSpinner
      *ngIf="uploading"
      [style]="{ width: '12px', height: '12px' }"
      styleClass="spinner"
      strokeWidth="3"
      fill="#EEEEEE"
      animationDuration=".5s"
    ></p-progressSpinner>
  </button>
  <input
    type="file"
    id="file"
    #uploadFile
    class="file"
    [accept]="
      isUploadVideo()
        ? 'video/quicktime, video/mp4'
        : 'audio/wav, audio/mpeg, audio/mpeg3, audio/x-m4a'
    "
    (change)="fileBrowseHandler($event)"
  />
</div>

<p-dialog
  [header]="'Review ' + type"
  [(visible)]="displayModal"
  [modal]="true"
  [maximizable]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div *ngIf="displayModal">
    <ng-container *ngIf="fileUploaded?.filename && type == mediaType.audio">
      <audio-caption [media]="fileUploaded" [link]="fileUploaded.url"></audio-caption>
    </ng-container>

    <video
      controls
      crossorigin="anonymous"
      style="width: 100%; max-height: 70vh"
      *ngIf="fileUploaded?.filename && type == mediaType.video"
    >
      <source [src]="fileUploaded?.url" type="video/mp4" />
      <source [src]="fileUploaded?.url" type="video/mov" />
      <track
        *ngIf="fileUploaded?.captionUrl"
        label="English"
        kind="subtitles"
        srclang="en"
        [src]="fileUploaded?.captionUrl"
        default
      />
      Your browser does not support the video tag.
    </video>
  </div>
</p-dialog>

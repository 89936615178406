import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NotificationService } from '../../../core/services/notification.service';
import { InfoServices } from '../../../main/main.services';
import { initVideoAudio, MediaType } from '../../constants';
import { IFile, Nullable } from '../../models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();

  mediaType = MediaType;
  @Input('type') type: MediaType = MediaType.video;
  @Input('typeUpload') typeUpload: string = '';
  @Input('extension') extension: string = '';
  @Input('textButton') textButton: string = '';
  @Input('disabled') disabled: boolean = false;
  @Input('fileMaxSizes') FILE_MAX_SIZES: number = 15;
  _file!: IFile;
  get file(): IFile {
    return this._file;
  }
  @Input('file') set media(value: IFile) {
    this._file = value;
    this.fileUploaded = value;
  }
  _fileEvent!: Nullable<File>;
  get fileEvent(): Nullable<File> {
    return this._fileEvent;
  }
  @Input('fileEvent') set fileEvent(value: Nullable<File>) {
    this._fileEvent = value;
    if (value) {
      this.onUploadFile(value);
    }
  }

  @Output() data: EventEmitter<any> = new EventEmitter<any>();
  @Output() droppedFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('uploadFile') myInputFile!: ElementRef;

  uploading = false;
  fileUploaded!: IFile;
  displayModal: boolean = false;

  constructor(
    private infoServices: InfoServices,
    private notiService: NotificationService
  ) {}

  ngOnInit() {
    this.isUploadVideo();
  }

  isUploadVideo() {
    return this.type === this.mediaType.video;
  }

  onUploadFile(file: File): void {
    if (file) {
      if (this.isUploadVideo()) {
        const isMovOrMp4 =
          file.type === 'video/quicktime' || file.type === 'video/mp4';
        if (!isMovOrMp4) {
          this.notiService.error({
            title: 'Fail',
            detail:
              'This file type is not supported. The following file types are supported: mov, mp4',
          });
          return;
        }
      } else {
        const isWavOrMp3 =
          file.type === 'audio/wav' ||
          file.type === 'audio/mpeg' ||
          file.type === 'audio/mpeg3' ||
          file.type === 'audio/x-m4a';
        if (!isWavOrMp3) {
          this.notiService.error({
            title: 'Fail',
            detail:
              'This file type is not supported. The following file types are supported: wav, mp3',
          });
          return;
        }
      }

      const isLimitSize = file.size! / 1024 / 1024 < this.FILE_MAX_SIZES;
      if (!isLimitSize) {
        this.notiService.error({
          title: 'Fail',
          detail: `The file size is too large. The maximum file size is ${this.FILE_MAX_SIZES}MB.`,
        });
        return;
      }
    }

    this.uploading = true;
    const { mediaId, userId } = this.fileUploaded;
    this.data.emit({
      ...initVideoAudio(userId, mediaId),
      type: this.type,
      isRecording: false,
    });
    const isRecording = false;
    this.infoServices
      .uploadFile(file, this.typeUpload, isRecording)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ data, errors }: any) => {
        this.uploading = false;
        if (!!data) {
          const mapdata =
            this.type === MediaType.audio ? data.uploadAudio : data.uploadVideo;
          this.fileUploaded = { ...mapdata, type: this.type };
          this.droppedFileEvent.emit();
          this.data.emit({
            ...mapdata,
            type: this.type,
            isRecording,
            userId,
            mediaId,
          });
          this.notiService.success({
            title: 'Success',
            detail: `Uploaded ${this.type}`,
          });
        }
        if (!!errors) {
          this.notiService.error({
            title: 'Fail',
            detail: errors[0]?.message,
          });
          this.myInputFile.nativeElement.value = '';
        }
      });
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    if (event?.target?.files[0]) {
      this.onUploadFile(event.target.files[0]);
    }
  }

  review() {
    this.displayModal = true;
  }

  removeFile() {
    this.myInputFile.nativeElement.value = '';
    const { mediaId, userId } = this.fileUploaded;
    this.fileUploaded = initVideoAudio(userId, mediaId);
    this.data.emit({
      ...this.fileUploaded,
      type: this.type,
      isRecording: false,
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

export class Funfact
{
  musicalArtists: string[] = [];
  hobbies: string[] = [];
  foods: string[] = [];

  constructor(init?: Partial<Funfact>)
  {
    if (init)
    {
      Object.assign(this, init);
    }
  };
}

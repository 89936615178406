import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { IdToken } from '@auth0/auth0-spa-js';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { ROLES } from '../../shared/constants/role';
import { setAccountAction } from '../../store/actions/user-account.actions';
import { AppState, selectUserAccount } from '../../store/reducer';
import { InfoServices } from '../main.services';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [ './register.component.scss' ],
  providers: []
})
export class RegisterComponent implements OnInit, OnDestroy {
  private completion$ = new Subject();
  account$: Observable<any>;
  account: any;
  token: string = '';
  claims: IdToken | null | undefined;
  dataToken: any;
  adminEmail: string = '';

  loading: boolean = true;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private infoServices: InfoServices,
    public store: Store<AppState>,
  ) {
    this.account$ = this.store.select(selectUserAccount);
  }

  ngOnInit(): void {
    this.auth.idTokenClaims$.pipe(
      switchMap((claims: IdToken | null | undefined) => {
        const token = this.route.snapshot.params[ 'token' ];
        const data = this.parseJwt(token);
        this.claims = claims;
        this.token = token;
        this.dataToken = data;
        this.adminEmail = data.createdByEmail;

        if (!this.claims) {
          return this.infoServices.checkTokenExpire(this.token, this.dataToken.type);
        }

        return of('');
      }),
      switchMap((res: any) => {
        const token = this.token;
        const data = this.dataToken;

        if (!this.claims) {
          const message = res.data.checkTokenExpire.message;
          if (message === 'valid') {
            this.auth.loginWithRedirect({
              screen_hint: 'signup',
              login_hint: data.email,
              appState: { target: `profile/register/${token}` }
            });
          } else {
            this.auth.loginWithRedirect({
              screen_hint: 'login',
              login_hint: data.email,
              appState: { target: `profile/register/${token}` }
            });
          }
        }

        const tokenAuth0 = this.claims ? this.claims.__raw : '';
        const email = this.claims ? this.claims.email : '';
        localStorage.setItem('token', tokenAuth0);
        if (data.email !== email) {
          return EMPTY;
        }

        if (data.type === ROLES.ORG_ADMIN) {
          // call api create account
          return this.infoServices.createOrganizationAdmin({ token });
        } else if (data.type === ROLES.ORG_USER) {
          return this.infoServices.createOrganizationUser({ token });
        } else if (data.type === ROLES.MENTOR) {
          return this.infoServices.createMentor({ token });
        }
        return EMPTY;
      })
    ).subscribe((data) => {
      if (data && data.email &&
        (data?.roles.includes(ROLES.ORG_USER) ||
          data?.roles.includes(ROLES.ORG_ADMIN) ||
          data?.roles.includes(ROLES.GLOBAL_ADMIN)
        )
      ) {
        // Navigate to admin page
        const token = localStorage.getItem('token');
        localStorage.removeItem('token');

        if (data?.roles.includes(ROLES.MENTOR)) {
          window.location.href = `${environment.ADMIN_URL}?token=${token}`;
          return;
        }
        this.auth.logout({ returnTo: `${environment.ADMIN_URL}?firstLogin=true&token=${token}`});
        return;
      }

      this.store.dispatch(setAccountAction({ account: data }));
      // Navigate to portal
      this.router.navigate([ '/home' ]);
      return;
    }, (error) => {
      console.error('error ', error.message);
      this.router.navigate([ `/invitation/expire`], { queryParams: {adminEmail: this.adminEmail }} );
    });

  }

  ngOnDestroy(): void {
    this.completion$.next();
    this.completion$.complete();
  }

  parseJwt(token: string) {
    var base64Url = token.split('.')[ 1 ];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

}

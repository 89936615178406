import { createReducer, on } from '@ngrx/store';
import { ITLStep, TIMELINE_STEPS } from '../../shared/constants';
import { deepCopy } from '../../shared/helpers';
import {
  CollegeDetail,
  CollegeExperience,
  JobDetail,
  JobExperience,
  Activity,
} from '../../shared/models';
import * as fromTimeline from './timeline.actions';
import { v4 as uuidv4 } from 'uuid';

export interface TimelineState {
  highSchoolExperience: any;
  additionalEducationExperience: CollegeExperience;
  jobExperience: JobExperience;
  stepper: ITLStep[];
  stepperInvalid: {
    isDisplay: boolean;
    hasStepInvalid: boolean;
    url: string;
    title: string;
  };
  applicantReview: {
    applicantReviewId: string;
    applicantId: string;
    timeline: any;
    status: string;
  }
}

export const initialState: TimelineState = {
  highSchoolExperience: null,
  additionalEducationExperience: { list: [], active: null, completedStep: {} },
  jobExperience: { list: [], active: null, completedStep: {} },
  stepper: deepCopy(TIMELINE_STEPS),
  stepperInvalid: {
    isDisplay: false,
    hasStepInvalid: false,
    url: '',
    title: '',
  },
  applicantReview: {
    applicantReviewId: '',
    applicantId: '',
    timeline: null,
    status: '',
  }
};

export const reducer = createReducer(
  initialState,
  on(
    fromTimeline.updateAllTimeline,
    (
      state,
      {
        highSchoolExperience,
        additionalEducationExperience,
        jobExperience,
        completedTimeline,
      }
    ) => {
      const list = jobExperience?.list ? [...jobExperience.list] : [];
      const activeJob = jobExperience?.active
        ? { ...jobExperience.active }
        : jobExperience?.list?.length
        ? { ...jobExperience.list[0] }
        : null;

      const listCollege = additionalEducationExperience?.list?.length
        ? [...additionalEducationExperience.list]
        : [];
      const activeCollege = additionalEducationExperience?.active
        ? { ...additionalEducationExperience?.active }
        : additionalEducationExperience?.list?.length
        ? { ...additionalEducationExperience.list[0] }
        : null;

      const activeHighschoolActivity = highSchoolExperience?.activeActivity
        ? { ...highSchoolExperience?.activeActivity }
        : highSchoolExperience?.activities?.length
        ? { ...highSchoolExperience?.activities[0] }
        : null;

      let newSteps = [...state.stepper];
      completedTimeline.forEach(
        (id) => (newSteps[id - 1] = { ...newSteps[id - 1], completed: true })
      );

      return {
        ...state,
        highSchoolExperience,
        additionalEducationExperience: {
          ...state.additionalEducationExperience,
          ...additionalEducationExperience,
          list: listCollege,
          active: activeCollege,
        },
        jobExperience: {
          ...state.jobExperience,
          ...jobExperience,
          list,
          active: activeJob,
        },

        stepper: newSteps,
        processing: {
          activeHighschoolActivity,
          activeCollege,
          activeJob,
          status: null,
        },
      };
    }
  ),
  on(fromTimeline.updateHighSchool, (state, { highSchoolExperience }) => {
    return { ...state, highSchoolExperience };
  }),
  on(fromTimeline.updateActivity, (state, { activity }) => {
    const { highSchoolExperience } = state;
    const index = highSchoolExperience.activities.findIndex(
      (x: Activity) => x.id === activity.id
    );
    let newActivities = [];
    if (index > -1) {
      newActivities = JSON.parse(
        JSON.stringify(highSchoolExperience.activities)
      );
      newActivities.splice(index, 1, activity);
    }
    return {
      ...state,
      highSchoolExperience: {
        ...state.highSchoolExperience,
        activities: newActivities,
      },
    };
  }),
  on(fromTimeline.updateActiveHighschoolActivity, (state, { activity }) => {
    return {
      ...state,
      highSchoolExperience: {
        ...state.highSchoolExperience,
        activeActivity: activity,
      },
    };
  }),
  on(
    fromTimeline.updateHighSchoolCompletedStep,
    (state, { completedStep, resetComplete }) => {
      return {
        ...state,
        highSchoolExperience: {
          ...state.highSchoolExperience,
          completedStep: {
            ...state.highSchoolExperience.completedStep,
            [completedStep]: !resetComplete,
          },
        },
      };
    }
  ),

  on(fromTimeline.resetInfo, (state, _: {}) => ({ ...state, ...initialState })),
  /*
   * College
   */
  on(fromTimeline.updateCollegeDetail, (state, { collegeDetail }) => {
    const { additionalEducationExperience } = state;
    let newArr: CollegeDetail[] = [];
    const { ...newCollege } = collegeDetail;
    if (
      !!additionalEducationExperience &&
      !!additionalEducationExperience.list
    ) {
      //Edit mode
      const index = additionalEducationExperience.list.findIndex(
        (item) => item.id == newCollege.id
      );
      const list = [...state.additionalEducationExperience.list];
      if (index > -1) {
        list[index] = newCollege;
        newArr = [...list];
      } else {
        newCollege.id = newCollege.id ? newCollege.id : uuidv4();
        newArr = [...list, newCollege];
      }
    }
    if (!additionalEducationExperience) {
      //Create mode
      newArr = [newCollege];
    }
    return {
      ...state,
      additionalEducationExperience: {
        ...state.additionalEducationExperience,
        list: newArr,
        active: newCollege,
      },
    };
  }),
  on(
    fromTimeline.updateCollegeActivities,
    (state, { activities, activeActivity, collegeId }) => {
      const collegeIndex = state.additionalEducationExperience.list.findIndex(
        (college: CollegeDetail) => college.id == collegeId
      );
      const college = {
        ...state.additionalEducationExperience.list[collegeIndex],
      };
      college.activities = activities;
      college.activeActivity = activeActivity;
      const newList = [...state.additionalEducationExperience.list];
      newList[collegeIndex] = college;
      const newCollegeExperience = { list: newList, active: college };
      return {
        ...state,
        additionalEducationExperience: {
          ...state.additionalEducationExperience,
          ...newCollegeExperience,
        },
      };
    }
  ),
  on(
    fromTimeline.updateCollegeMediaForActivity,
    (state, { activity, collegeId }) => {
      const { additionalEducationExperience } = state;
      const collegeIndex = additionalEducationExperience.list.findIndex(
        (college: CollegeDetail) => college.id == collegeId
      );
      const college = { ...additionalEducationExperience.list[collegeIndex] };
      const activityIndex = college?.activities?.findIndex(
        (x: Activity) => x.id === activity.id
      );
      let newActivities = [];
      if (activityIndex !== undefined && activityIndex > -1) {
        newActivities = JSON.parse(JSON.stringify(college.activities));
        newActivities.splice(activityIndex, 1, activity);
      }
      college.activities = newActivities;
      const newList = [...additionalEducationExperience.list];
      newList[collegeIndex] = college;
      const newCollegeExperience = { list: newList, active: college };

      return {
        ...state,
        additionalEducationExperience: {
          ...state.additionalEducationExperience,
          ...newCollegeExperience,
        },
      };
    }
  ),
  on(
    fromTimeline.updateActiveCollegeActivity,
    (state, { activity, collegeId }) => {
      const { additionalEducationExperience } = state;
      const collegeIndex = additionalEducationExperience.list.findIndex(
        (college: CollegeDetail) => college.id == collegeId
      );
      const college = { ...additionalEducationExperience.list[collegeIndex] };
      college.activeActivity = activity;
      const newList = [...additionalEducationExperience.list];
      newList[collegeIndex] = college;
      const newCollegeExperience = { list: newList, active: college };

      return {
        ...state,
        additionalEducationExperience: {
          ...state.additionalEducationExperience,
          ...newCollegeExperience,
        },
      };
    }
  ),
  on(fromTimeline.updateActiveCollege, (state, { college }) => {
    const { additionalEducationExperience } = state;
    const newList = [...additionalEducationExperience.list];
    const newCollegeExperience = { list: newList, active: college };
    return {
      ...state,
      additionalEducationExperience: {
        ...state.additionalEducationExperience,
        ...newCollegeExperience,
      },
    };
  }),
  on(fromTimeline.clearEmptyCollege, (state) => {
    const {
      additionalEducationExperience: { list },
    } = state;
    const newList = list.filter((degree) => !!degree?.highestDegree);
    const newCollegeExperience = { list: newList, active: list[0] };
    return {
      ...state,
      additionalEducationExperience: {
        ...state.additionalEducationExperience,
        ...newCollegeExperience,
      },
    };
  }),
  on(
    fromTimeline.updateCollegeCompletedStep,
    (state, { completedStep, resetComplete }) => {
      return {
        ...state,
        additionalEducationExperience: {
          ...state.additionalEducationExperience,
          completedStep: {
            ...state.additionalEducationExperience.completedStep,
            [completedStep]: !resetComplete,
          },
        },
      };
    }
  ),
  on(fromTimeline.resetCollegeCompletedStep, (state) => {
    return {
      ...state,
      additionalEducationExperience: {
        ...state.additionalEducationExperience,
        completedStep: {},
      },
    };
  }),
  /*
   * JOB
   */
  on(fromTimeline.updateJobDetail, (state, { job }) => {
    const { jobExperience } = state;
    let newArr: JobDetail[] = [];
    const { ...newJob } = job;

    if (!!jobExperience && !!jobExperience.list) {
      //Edit mode
      const index = jobExperience.list.findIndex(
        (item) => item.id == newJob.id
      );
      const list = [...state.jobExperience.list];
      if (index > -1) {
        list[index] = newJob;
        newArr = [...list];
      } else {
        newJob.id = newJob.id ? newJob.id : uuidv4();
        newArr = [...list, newJob];
      }
    }
    if (!jobExperience) {
      //Create mode
      newArr = [newJob];
    }
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        list: newArr,
        active: newJob,
      },
    };
  }),
  on(fromTimeline.updateOnetTitleJobRecent, (state, { onetTitle, jobId }) => {
    const jobIndex = state.jobExperience.list.findIndex(
      (job: JobDetail) => job.id == jobId
    );
    const job = { ...state.jobExperience.list[jobIndex] };
    job.recentJob = {
      ...job.recentJob,
      onetTitle: onetTitle,
    };
    const newList = [...state.jobExperience.list];
    newList[jobIndex] = job;
    const newJobExperience = { list: newList, active: job };
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        ...newJobExperience,
      },
    };
  }),
  on(fromTimeline.updateJobSkill, (state, { skills, jobId }) => {
    const jobIndex = state.jobExperience.list.findIndex(
      (job: JobDetail) => job.id == jobId
    );
    const job = { ...state.jobExperience.list[jobIndex] };
    job.skills = skills;
    const newList = [...state.jobExperience.list];
    newList[jobIndex] = job;
    const newJobExperience = { list: newList, active: job };
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        ...newJobExperience,
      },
    };
  }),
  on(fromTimeline.updateJobMedia, (state, { media, jobId }) => {
    const jobIndex = state.jobExperience.list.findIndex(
      (job: JobDetail) => job.id == jobId
    );
    const job = { ...state.jobExperience.list[jobIndex] };
    job.media = { mediaId: media.mediaId };
    const newList = [...state.jobExperience.list];
    newList[jobIndex] = job;
    const newJobExperience = { list: newList, active: job };
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        ...newJobExperience,
      },
    };
  }),
  on(fromTimeline.updateJobLikeMostMedia, (state, { media, jobId }) => {
    const jobIndex = state.jobExperience.list.findIndex(
      (job: JobDetail) => job.id == jobId
    );
    const job = { ...state.jobExperience.list[jobIndex] };
    job.likeMost = { mediaId: media.mediaId };
    const newList = [...state.jobExperience.list];
    newList[jobIndex] = job;
    const newJobExperience = { list: newList, active: job };
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        ...newJobExperience,
      },
    };
  }),
  on(fromTimeline.updateJobAdviceMedia, (state, { media, jobId }) => {
    const jobIndex = state.jobExperience.list.findIndex(
      (job: JobDetail) => job.id == jobId
    );
    const job = { ...state.jobExperience.list[jobIndex] };
    job.advice = { mediaId: media.mediaId };
    const newList = [...state.jobExperience.list];
    newList[jobIndex] = job;
    const newJobExperience = { list: newList, active: job };
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        ...newJobExperience,
      },
    };
  }),
  on(fromTimeline.updateActiveJob, (state, { job }) => {
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        active: job,
      },
    };
  }),
  on(
    fromTimeline.updateJobCompletedStep,
    (state, { completedStep, resetComplete }) => {
      return {
        ...state,
        jobExperience: {
          ...state.jobExperience,
          completedStep: {
            ...state.jobExperience.completedStep,
            [completedStep]: !resetComplete,
          },
        },
      };
    }
  ),
  on(fromTimeline.resetJobCompletedStep, (state) => {
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        completedStep: {},
      },
    };
  }),
  on(
    fromTimeline.updateStepperTimeline,
    (state, { completed, active, resetComplete }) => {
      let newSteps = [...state.stepper];

      if (active) {
        newSteps = newSteps.map((step) => ({ ...step, highlight: false }));
        newSteps[active - 1] = { ...newSteps[active - 1], highlight: true };
      }
      if (completed) {
        completed.forEach(
          (id) =>
            (newSteps[id - 1] = {
              ...newSteps[id - 1],
              completed: !resetComplete,
            })
        );
      }
      return { ...state, stepper: newSteps };
    }
  ),
  on(fromTimeline.updateStepperInvalidTimeline, (state, stepperInvalid) => {
    return { ...state, stepperInvalid };
  }),
  on(fromTimeline.updateDisplayModalInvalidTimeline, (state, { isDisplay }) => {
    return {
      ...state,
      stepperInvalid: {
        ...state.stepperInvalid,
        isDisplay: isDisplay,
      },
    };
  }),
  on(fromTimeline.clearEmptyJob, (state) => {
    const {
      jobExperience: { list },
    } = state;
    const newList = list.filter((job) => !!job?.recentJob);
    const newJobExperience = { list: newList, active: list[0] };
    return {
      ...state,
      jobExperience: {
        ...state.jobExperience,
        ...newJobExperience,
      },
    };
  }),
  on(fromTimeline.updateApplicantReview, (state, { applicantReview }) => {
    return {
      ...state,
      applicantReview: { ...applicantReview }
    };
  })
);

import { createAction, createReducer, on, props } from '@ngrx/store';

export type TimelineNavType = { from: string, to: string, activeIndex?: number, activeIndexActivity?: number; }
/**
 * Summary navigate action
 */
export const updateSummaryNavigation = createAction(
  `[Timeline] Update Summary Navigation`, props<{ from: string, to: string, activeIndex?: number, activeIndexActivity?: number; }>()
);
export const clearSummaryNavigation = createAction(
  `[Timeline] Clear Summary Navigation`
);
/**
 * Summary navigate reducer
 */
export interface TimelineNavigationState {
  summaryNavigate: TimelineNavType | null;
};


export const initialState: TimelineNavigationState = {
  summaryNavigate: null
};

export const reducer = createReducer(
  initialState,
  on(
    updateSummaryNavigation,
    (state, payload) => {

      return {
        ...state,
        summaryNavigate: payload
      };
    }
  ),
  on(
    clearSummaryNavigation,
    (state) => {

      return {
        ...state,
        summaryNavigate: null
      };
    }
  )
);

export class PersonalInfo {
  firstName: string = '';
  lastName: string = '';
  age!: number;
  preferredPronouns: string = '';
  primaryLanguage: string = '';
  secondaryLanguage: string[] = [];
  categories: string[] = [];
  linkedinURL: string = '';
  ethnicity: string = '';
  zipCode!: number;
  email: string = '';
  bioPicture = '';
  roles: string[] = [];
  organizations?: Organization[] = [];
  constructor(init?: Partial<PersonalInfo>) {
    if (init) {
      Object.assign(this, init);
    }
  };
}

export interface Organization {
  type: string;
  organization: IOrganization;
}

export interface IOrganization {
  organizationId: string;
  name: string;
}

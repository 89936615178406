import { createAction, props } from "@ngrx/store";
const prefix = 'User Account';

export const getAccountAction = createAction(`[${ prefix }] Get user account`);

export const setAccountAction = createAction(
  `[${ prefix }] Set account`,
  props<{ account: any; }>()
);
export const resetAccountAction = createAction(
  `[${ prefix }] Reset account`
);
export const getTokenToValueFormResetPasswordAction = createAction(
  `[${ prefix }] Get Token To Value - Success`,
  props<{ token: any; }>()
);

import { createReducer, on } from '@ngrx/store';
import { ROUTE, Status } from '../../../shared/constants';
import { IStepItem } from '../../models';
import * as fromProfile from '../actions/profile.actions';
import { Acknowledgement, Funfact, PersonalInfo, UserReviewInput } from '../../../shared/models';

export const initStepper: IStepItem[] = [
  { number: 1, title: 'Personal Info', link: ROUTE.PERSONAL_INFO, completed: false, active: false },
  { number: 2, title: 'Fun Facts', link: ROUTE.FUNFACTS, completed: false, active: false },
  { number: 3, title: 'Acknowledgement', link: ROUTE.ACKNOWLEDGEMENT, completed: false, active: false }
];

export interface ProfileState
{
  info: any;
  funfacts: any;
  acknowledgement: any;
  status: any;
  review: any;
  stepper: IStepItem[];
}

export const initialState: ProfileState = {
  info: new PersonalInfo(),
  funfacts: new Funfact(),
  acknowledgement: new Acknowledgement(),
  status: {
    profile: Status.NONE,
    assessment: Status.NONE,
    timeline: Status.NONE,
  },
  review: new UserReviewInput(),
  stepper: initStepper
};

export const reducer = createReducer(
  initialState,
  on(
    fromProfile.updateProfile,
    (state, { profile, funfacts, acknowledgement, status, review }) => ({ ...state, info: profile, funfacts, acknowledgement, status, review })
  ),
  on(
    fromProfile.updatePersonalInfo,
    (state, { profile }) => ({ ...state, info: profile })
  ),
  on(
    fromProfile.updateFunfacts,
    (state, { funfacts }) => ({ ...state, funfacts })
  ),
  on(
    fromProfile.updateAcknowledgement,
    (state, { acknowledgement }) => ({ ...state, acknowledgement })
  ),
  on(
    fromProfile.updateStepper,
    (state, { completed, active }) => {
      let newSteps = [ ...state.stepper ];
      if (active) {
        newSteps = newSteps.map(step => ({ ...step, active: false }));
        newSteps[ active - 1 ] = { ...newSteps[ active - 1 ], active: true };
      }
      if (completed) {
        completed.forEach(id => newSteps[ id - 1 ] = { ...newSteps[ id - 1 ], completed: true });
      }
      return { ...state, stepper: newSteps };
    }
  ),
  on(
    fromProfile.resetInfo,
    (state) => ({ ...state, ...initialState })
  ),
  on(
    fromProfile.changeTimelineStatus,
    (state, { status }) => {

      const newStatus = { ...state.status, timeline: status };
      return {
        ...state,
        status: newStatus
      };
    }
  )
);

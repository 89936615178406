export interface IOption {
  number: number;
  title: string;
  icon: string,
  active: boolean;
}

export const MEDIA_OPTION: IOption[] = [
  { number: 1, title: 'Text', icon: 'book', active: false },
  { number: 2, title: 'Video', icon: 'play', active: false },
  { number: 3, title: 'Audio', icon: 'volume-up', active: false },
];

export const ROUTE = {
  DASHBOARD: '/profile/dashboard',
  PERSONAL_INFO: '/profile/personal-info',
  FUNFACTS: '/profile/fun-facts',
  ACKNOWLEDGEMENT: '/profile/acknowledgement',
  TIMELINE: '/timeline',
  HIGHSCHOOL_1: '/timeline/highschool-step1',
  HIGHSCHOOL_2: '/timeline/highschool-step2',
  HIGHSCHOOL_3: '/timeline/highschool-step3',
  HIGHSCHOOL_4: '/timeline/highschool-step4',
  HIGHSCHOOL_SUMMARY: '/timeline/highschool-summary',
  COLLEGE_1: '/timeline/college-step1',
  COLLEGE_2: '/timeline/college-step2',
  COLLEGE_3: '/timeline/college-step3',
  COLLEGE_4: '/timeline/college-step4',
  COLLEGE_5: '/timeline/college-step5',
  COLLEGE_6: '/timeline/college-step6',
  COLLEGE_SUMMARY: '/timeline/college-summary',
  JOB_1: '/timeline/job-step1',
  JOB_2: '/timeline/job-step2',
  JOB_3: '/timeline/job-step3',
  JOB_4: '/timeline/job-step4',
  JOB_5: '/timeline/job-step5',
  JOB_6: '/timeline/job-step6',
  SUMMARY: '/timeline/summary',
  ASSESSMENTS: '/profile/assessments/personality',
};

import { Activity, Media, NameAndId, Nullable } from '.';

export type CollegeExperience = {
  list: CollegeDetail[];
  active: Nullable<CollegeDetail>;
  completedStep?: Nullable<CollegeCompletedStep>;
};

export class CollegeDetail {
  startYear!: Nullable<number>;
  endYear!: Nullable<number>;
  highestDegree: string = '';
  id: string = '';
  activities?: Activity[] = [];
  activeActivity!: Nullable<Activity>;
  topAdvise?: Partial<Media>;
  whyChoose?: Partial<Media>;
  degreeProgram?: NameAndId;
  institution?: NameAndId;

  constructor(init?: Partial<CollegeDetail>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class CollegePath {
  root: string = '';
  activityId?: number = 10;
  isFromActivity: boolean = false;

  constructor(init?: Partial<CollegePath>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class CollegeMedia {
  type: string = '';
  link: string = '';
  content: string = '';
  path?: CollegePath;

  constructor(init?: Partial<CollegeMedia>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class CollegeCompletedStep {
  COLLEGE_1?: boolean = false;
  COLLEGE_2?: boolean = false;
  COLLEGE_3?: boolean = false;
  COLLEGE_4?: boolean = false;
  COLLEGE_5?: boolean = false;
  COLLEGE_6?: boolean = false;
  COLLEGE_MORE?: boolean = false;
  COLLEGE_SUMMARY?: boolean = false;

  constructor(init?: Partial<CollegeCompletedStep>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

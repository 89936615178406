import { Nullable } from '.';

export class HighSchoolExperience {
  year!: Nullable<number>;
  activities?: Array<Activity>;
  topAdvise?: Partial<Media>;
  activeActivity!: Nullable<Activity>;
  completedStep?: Nullable<HighSchoolCompletedStep>;

  constructor(init?: Partial<HighSchoolExperience>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class Path {
  root: string = '';
  activityId: string = '';
  isFromActivity: boolean = false;

  constructor(init?: Partial<Path>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class Activity {
  id: string = '';
  name: string = '';
  checked?: boolean = false;
  skills?: Skill[];
  media?: Partial<Media>;
  completedStep?: Nullable<ActivityCompletedStep>;

  constructor(init?: Partial<Activity>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class ActivityCompletedStep {
  HIGHSCHOOL_2?: boolean = false;
  HIGHSCHOOL_3?: boolean = false;

  constructor(init?: Partial<ActivityCompletedStep>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class Skill extends Activity {
  description?: string = '';
  constructor(init?: Partial<Skill>) {
    super(init);
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class Media {
  userId = '';
  mediaId = '';
  type: string = '';
  link: string = '';
  filename: string = '';
  content: string = '';
  isRecording: boolean = false;
  captionGoogle?: any = {};
  captionUrl?: string = '';
  captionText?: string = '';
  captionEmbedded?: string = '';
  isInvalid?: boolean = false;
  isApproved?: boolean = true;
  reason?: string = '';
  __typename?: string;

  constructor(init?: Partial<Media>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class HighSchoolCompletedStep {
  HIGHSCHOOL_1?: boolean = false;
  HIGHSCHOOL_2?: boolean = false;
  HIGHSCHOOL_3?: boolean = false;
  HIGHSCHOOL_4?: boolean = false;
  HIGHSCHOOL_SUMMARY?: boolean = false;

  constructor(init?: Partial<HighSchoolCompletedStep>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export interface IMediaList {
  [mediaId: string]: Media;
}

export interface IYearGraduation {
  name: number;
  id: number;
}

export interface IActivity {
  id: string;
  name: string;
  description?: string;
  key: string;
  checked?: boolean;
}
export type ISkill = IActivity;

export interface IEducationLevelTypes {
  id: string;
  name: string;
}

export interface IProgram {
  name: string;
}

export interface IDegreePrograms {
  [index: string]: IProgram[];
}

export interface IMediaStatus {
  link: string;
  type: string;
  userId: string;
  content: string;
  mediaId: string;
  filename: string;
  reason?: string;
  isApproved: boolean;
  isRecording: boolean;
}

export enum DegreeEnum {
  HighSchool = 'High School',
  PostSecondaryCertificate = 'Post-Secondary Certificate',
  SomeCollegeCourses = 'Some College Courses',
  AssociateDegree = "Associate's Degree",
  BachelorDegree = "Bachelor's Degree",
  PostBaccalaureateCertificate = "Post-Baccalaureate Certificate",
  MasterDegree = "Master's Degree",
  PostMasterCertificate = "Post-Master's Certificate",
  DoctorateDegree = "Doctorate Degree",
  PostDoctoralTraining = "Post-Doctoral Training"
}

export enum DegreeEnumValue {
  HighSchool = 1,
  PostSecondaryCertificate = 2,
  SomeCollegeCourses = 3,
  AssociateDegree = 4,
  BachelorDegree = 5,
  PostBaccalaureateCertificate = 6,
  MasterDegree = 7,
  PostMasterCertificate = 8,
  DoctorateDegree = 9,
  PostDoctoralTraining = 10
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { Apollo, Query } from 'apollo-angular';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { Acknowledgement, Funfact, Media, UserReviewInput, UserStatusInput, UserTimelineInput } from '../shared/models';
import {
  aboutQuery, accountMutation, acknowledgementMutation,
  activityListQuery, checkTokenExpireMutation, createMentor, createOrganizationAdmin, createOrganizationUser, ethnicityQuery,
  funfactMutation,
  getEducationLevelTypesQuery, getQueryOccupationByOnetCode,
  getQuerySkillOnetByActivityId, industriesQuery,
  infoMutation,
  infoQuery,
  infoUserQuery,
  quizMutation,
  quizQuestionQuery, sendEmailConnectWithUsQuery, sendEmailSubmitTimelineMutation, skillsOnetKSAsQuery, userAdditionalInfoMutation,
  userInfoMutation,
  userMediaQuery,
  userReviewMutation,
  userStatusMutation
} from './main.gql';

@Injectable({
  providedIn: 'root'
})
export class InfoServices {
  constructor(private apollo: Apollo, private http: HttpClient) { }

  getUserInfo(userId: string) {
    return this.getQuery(userId).valueChanges.pipe(
      map((result) => (result.data as any).getUserInfoById)
    );
  }

  getUserInfoDt() {
    return this.getUserQuery().valueChanges.pipe(
      map((result) => (result.data as any).getUserInfo)
    );
  }

  setUserAccount(email: string) {
    return this.apollo.mutate({
      mutation: accountMutation,
      variables: {
        input: { email, role: 'MENTOR' }
      }
    }).pipe(map(result => (result.data as any).setUserAccount));
  }

  sendEmailConnectWithUs(data: any) {
    return this.sendEmail(data).valueChanges.pipe(
      map((result) => (result.data as any).sendEmailConnectWithUs)
    );
  }

  sendEmail(input: any) {
    return this.apollo.watchQuery<Query>({
      query: sendEmailConnectWithUsQuery,
      variables: {
        input
      },
    });
  }

  getUserQuizQuestion(userId: string, quizId?: number) {
    return this.getQueryQuizQuestion(userId, quizId).valueChanges.pipe(
      map((result) => (result.data as any).getUserQuizQuestion)
    );
  }

  getEducationLevelTypes() {
    return this.getQueryEducationLevelTypes().valueChanges.pipe(
      map((result) => (result.data as any).vpEducationLevelTypes)
    );
  }

  checkTokenExpire(token: string, type: string) {
    return this.apollo.mutate({
      mutation: checkTokenExpireMutation,
      variables: {
        input: { token, type }
      }
    });
  }


  refreshInfo(userId: string) {
    this.getQuery(userId).refetch();
  }

  getQuery(userId: string) {
    return this.apollo.watchQuery<Query>({
      query: infoQuery,
      variables: {
        userId
      },
    });
  }

  getUserQuery() {
    return this.apollo.watchQuery<Query>({
      query: infoUserQuery,
    });
  }


  getQueryQuizQuestion(userId: string, quizId?: number) {
    return this.apollo.watchQuery<Query>({
      query: quizQuestionQuery,
      variables: {
        userId,
        quizId
      },
    });
  }

  getQueryEducationLevelTypes() {
    return this.apollo.watchQuery<Query>({
      query: getEducationLevelTypesQuery
    });
  }

  setUserQuiz(quiz: any) {
    return this.apollo.mutate({
      mutation: quizMutation,
      variables: {
        input: quiz
      }
    });
  }

  setPersonalInfo(info: any) {
    return this.apollo.mutate({
      mutation: infoMutation,
      variables: {
        input: info
      }
    });
  }

  setFunfacts(funfact: Funfact & { userId: string; }) {
    return this.apollo.mutate({
      mutation: funfactMutation,
      variables: {
        input: funfact
      }
    });
  }

  setUserAcknowledgement(ack: Acknowledgement & { userId: string; }) {
    return this.apollo.mutate({
      mutation: acknowledgementMutation,
      variables: {
        input: ack
      }
    });
  }


  getAbout() {
    return this.getHearAboutUs().valueChanges.pipe(
      map((result) => (result.data as any).getHearAboutUs)
    );
  }

  getHearAboutUs() {
    return this.apollo.watchQuery<Query>({
      query: aboutQuery,
      variables: {},
    });
  }

  uploadAvatar(file: any) {
    const operations = {
      query: ` mutation uploadAvatar($file: Upload!) {
        uploadAvatar(input: {file: $file}) {
          filename
          mimetype
          encoding
          url
        }
      }`,
      variables: {
        file: null,
      },
    };
    const _map = {
      file: [ 'variables.file' ],
    };
    var fd = new FormData();
    fd.append('operations', JSON.stringify(operations));
    fd.append('map', JSON.stringify(_map));
    fd.append('file', file, file.name);

    return this.http.post(environment.API_URL, fd);
  }

  uploadFile(file: File, type: string, isRecording: boolean) {
    const operations = {
      query: ` mutation ${type}($file: Upload!, $isRecording: Boolean) {
        ${type}(input: {file: $file, isRecording:$isRecording }) {
          filename
          mimetype
          encoding
          url
          isRecording
        }
      }`,
      variables: {
        file: null,
        isRecording,
      },
    };
    const _map = {
      file: [ "variables.file" ]
    };
    const fd = new FormData();
    fd.append('operations', JSON.stringify(operations));
    fd.append('map', JSON.stringify(_map));
    fd.append('file', file, file.name);
    return this.http.post(environment.API_URL, fd);
  }

  setUserMedia(mediaInput: Media) {
    return this.apollo
      .mutate({
        mutation: userMediaQuery,
        variables: {
          input: mediaInput,
        },
      })
      .pipe(map((result) => (result.data as any).setUserMedia));
  }

  getActivityList() {
    return this.getQueryActivities().valueChanges.pipe(
      map((result) => (result.data as any))
    );
  }

  getQueryActivities() {
    return this.apollo.watchQuery<Query>({
      query: activityListQuery,
      variables: {},
    });
  }

  getOccupationByOnetCode(onetCode: string) {
    return this.getQueryOccupationByOnetCode(onetCode).valueChanges.pipe(
      map((result: any) => {
        let occupationKSAs = result?.data?.occupationByOnetCode?.occupationKSAs;
        if (occupationKSAs) {
          occupationKSAs = occupationKSAs?.map((skill: any) => {
            let { importance, ksa } = skill;
            return { importance, level: 1, ...ksa, checked: false };
          });
          return {
            onetTitle: result.data.occupationByOnetCode?.onetTitle,
            occupationKSAs
          };
        }
        else {
          return {
            onetTitle: result.data.occupationByOnetCode?.onetTitle,
            occupationKSAs: [],
          };
        }
      })
    );
  }

  getQueryOccupationByOnetCode(onetCode: string) {
    return this.apollo.watchQuery<Query>({
      query: getQueryOccupationByOnetCode,
      variables: {
        onetCode
      },
    });
  }

  async getSkillOnetByActivityId(id: string) {
    try {
      let result = await this.getQuerySkillOnetByActivityId(id);
      if (result.data.activityById?.onetKSAs) {
        return result.data.activityById?.onetKSAs as any;
      }
      else {
        return [];
      }
    } catch (error) {
      return error;
    }
    // TODO: change url client cheat
    // return this.getQuerySkillOnetByActivityId(id).valueChanges.pipe(
    //   map((result: any) => {
    //     return result.data.activityById.onetKSAs;
    //   })
    // );
  }

  getQuerySkillOnetByActivityId(id: string) {
    const apolloOnetTempUrl = new ApolloClient({
      uri:
        environment.API_URL === 'https://tmm2-dev-api.watasolutions.app' ||
        environment.API_URL === 'http://localhost:5151' ?
        'https://vm-api-dev.xp4.careers' : environment.API_URL,
      cache: new InMemoryCache()
    });
    return apolloOnetTempUrl.query({
      query: getQuerySkillOnetByActivityId,
      variables: {
        id
      },
    });
    // TODO: change url client cheat
    // return apolloOnetTempUrl.watchQuery<Query>({
    //   query: getQuerySkillOnetByActivityId,
    //   variables: {
    //     id
    //   },
    // });
  }


  getSkillsOnetKSAs() {
    return this.getQuerySkillsOnetKSAs().valueChanges.pipe(
      map((result: any) => {
        return result.data.onetKSAs;
      })
    )
  }

  getQuerySkillsOnetKSAs() {
    return this.apollo.watchQuery<Query>({
      query: skillsOnetKSAsQuery,
    });
  }

  getIndustries() {
    return this.apollo.watchQuery<Query>({
      query: industriesQuery,
      variables: {},
    }).valueChanges.pipe(
      map((result) => (result.data as any))
    );
  }

  getEthnicityList() {
    return this.apollo.watchQuery<Query>({
      query: ethnicityQuery,
      variables: {
        filter: { opeId: { eq: "00339900" } }
      },
    }).valueChanges.pipe(
      map((result) => (result.data as any))
    );;
  }

  setUserTimeline(userTimeline: UserTimelineInput & { userId: string; }) {
    return this.apollo
      .mutate({
        mutation: userInfoMutation,
        variables: {
          input: userTimeline,
        },
      })
      .pipe(map((result) => (result.data as any).setUserTimeline));
  }

  setUserStatus(userStatusInput: UserStatusInput & { userId: string; }) {
    return this.apollo
      .mutate({
        mutation: userStatusMutation,
        variables: {
          input: userStatusInput,
        },
      })
      .pipe(map((result) => (result.data as any).setUserStatus));
  }

  setUserAdditionalInfo(info: any) {
    return this.apollo
      .mutate({
        mutation: userAdditionalInfoMutation,
        variables: {
          input: info,
        },
      })
      .pipe(map((result) => (result.data as any).setUserAdditionalInfo));
  }

  setUserReview(userReviewInput: UserReviewInput & { userId: string; }) {
    return this.apollo
      .mutate({
        mutation: userReviewMutation,
        variables: {
          input: userReviewInput,
        },
      })
      .pipe(map((result) => (result.data as any).setUserReview));
  }

  sendEmailSubmitTimeline(userId: string) {
    return this.apollo
      .mutate({
        mutation: sendEmailSubmitTimelineMutation,
        variables: {
          input: { userId },
        },
      })
      .pipe(map((result) => (result.data as any).sendEmailSubmitTimeline));
  }

  createOrganizationAdmin(input: any) {
    return this.apollo
      .mutate({
        mutation: createOrganizationAdmin,
        variables: {
          input,
        },
      })
      .pipe(map((result) => (result.data as any).createOrganizationAdmin));
  }

  createOrganizationUser(input: any) {
    return this.apollo
      .mutate({
        mutation: createOrganizationUser,
        variables: {
          input,
        },
      })
      .pipe(map((result) => (result.data as any).createOrganizationUser));
  }

  createMentor(input: any) {
    return this.apollo
      .mutate({
        mutation: createMentor,
        variables: {
          input,
        },
      })
      .pipe(map((result) => (result.data as any).createMentor));
  }

  getStatic() {
    return of({
      preferredPronouns: PREFERRED_PRONOUNS,
      languages: LANGUAGES,
      categories: CATEGORIES,
      ethnicity: ETHNICITY
    });
  }
}

const PREFERRED_PRONOUNS = [
  { name: 'She/Her/Hers' },
  { name: 'He/Him/His' },
  { name: 'They/Them/Theirs' }
];

const LANGUAGES = [
  {
    name: 'English', code: 'en'
  },
  {
    name: 'Spanish', code: 'sp'
  }
];
const CATEGORIES = [
  {
    name: 'Educational Services'
  },
  {
    name: 'Finance and Insurance'
  },
  {
    name: 'Wholesale Trade'
  }
];
const ETHNICITY = [
  {
    name: 'Americans'
  },
  {
    name: 'Mexicans'
  },
  {
    name: 'Arabs'
  },
  {
    name: 'Asian people'
  },
  {
    name: 'Romani people'
  }
];

export enum Status {
  NONE = "NONE",
  STARTED = "STARTED",
  INPROGRESS = "INPROGRESS",
  COMPLETED = "COMPLETED",
}

export interface IStatus {
  profile?: Status;
  assessment?: Status;
  timeline?: Status;
}

export const STATUS = {
  NONE: Status.NONE,
  STARTED: Status.STARTED,
  INPROGRESS: Status.INPROGRESS,
  COMPLETED: Status.COMPLETED
};

export enum USER_QUIZ {
  EXPLORER = 'Explorer',
}

export enum MediaType {
  text = 'text',
  audio = 'audio',
  video = 'video',
}
export interface IMedia {
  userId: string;
  mediaId: string;
  link: string;
  captionGoogle?: any;
  captionUrl?: string;
  captionText?: string;
  captionEmbedded?: string;
  type: MediaType;
  filename?: string;
  content?: string;
  isRecording?: boolean;
}

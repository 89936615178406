import { Media, NameAndId, Nullable } from '.';

export type JobExperience = {
  list: JobDetail[];
  active: Nullable<JobDetail>;
  completedStep?: Nullable<JobCompletedStep>;
};
export class JobDetail {
  startedAt!: number;
  endedAt!: number;
  recentJob!: RecentJob;
  employer: string = '';
  currentlyWork: boolean = false;
  id: string = '';
  skills: SkillOnet[] = [];
  media?: Partial<Media>;
  likeMost?: Partial<Media>;
  advice?: Partial<Media>;

  constructor(init?: Partial<JobDetail>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

interface RecentJob extends NameAndId {
  onetTitle?: string;
}

export class JobSkill {
  id: string = '';
  name: string = '';
  level?: number = 1;
  checked?: boolean = false;
  constructor(init?: Partial<JobSkill>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class SkillOnet {
  id: string = '';
  name: string = '';
  description?: string = "";
  importance?: number;
  level?: number = 1;
  checked?: boolean = false;
  constructor(init?: Partial<JobSkill>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

export class JobCompletedStep {
  JOB_1?: boolean = false;
  JOB_2?: boolean = false;
  JOB_3?: boolean = false;
  JOB_4?: boolean = false;
  JOB_5?: boolean = false;
  JOB_6?: boolean = false;
  JOB_MORE?: boolean = false;
  SUMMARY?: boolean = false;

  constructor(init?: Partial<JobCompletedStep>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

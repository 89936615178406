import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { errorRoute } from './layouts/error/error.route';
import { RegisterComponent } from './main/register/register.component';

const LAYOUT_ROUTES = [ ...errorRoute ];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          redirectTo: 'home',
          pathMatch: 'full'
        },
        { path: 'profile/register/:token', component: RegisterComponent },
        {
          path: 'profile',
          loadChildren: () =>
            import('./main/main.module').then((m) => m.MainModule),
          canActivate: [ AuthGuard ]
        },
        {
          path: 'timeline',
          loadChildren: () =>
            import('./timeline/timeline.module').then((m) => m.TimelineModule),
          canActivate: [ AuthGuard ]
        },
        {
          path: 'home',
          loadChildren: () =>
            import('./home/home.module').then((m) => m.HomeModule),
        },
        ...LAYOUT_ROUTES,
      ]
    ),
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }

import { createAction, props } from '@ngrx/store';
import {
  Nullable,
  UserStatusInput,
  CollegeDetail,
  CollegeMedia,
  Activity,
  HighSchoolExperience,
  Media,
  JobDetail,
  JobSkill,
  JobCompletedStep,
  CollegeCompletedStep,
} from '../../shared/models';

const prefix = 'Timeline';

export const updateAllTimeline = createAction(
  `[${prefix}] Update All Timeline`,
  props<{
    jobExperience: {
      list: JobDetail[];
      active: Nullable<JobDetail>;
      completedStep?: Nullable<JobCompletedStep>;
    };
    highSchoolExperience: HighSchoolExperience;
    additionalEducationExperience: {
      list: CollegeDetail[];
      active: Nullable<CollegeDetail>;
      completedStep?: Nullable<CollegeCompletedStep>;
    };
    completedTimeline: number[];
  }>()
);

export const updateHighSchool = createAction(
  `[${prefix}] Update high school experience`,
  props<{ highSchoolExperience: HighSchoolExperience }>()
);

export const updateActivity = createAction(
  `[${prefix}] Update activity`,
  props<{ activity: Activity }>()
);

export const updateActiveHighschoolActivity = createAction(
  `[${prefix}] Update active high school activity`,
  props<{ activity: Activity }>()
);

export const updateHighSchoolCompletedStep = createAction(
  `[${prefix}] Update High School Completed Step`,
  props<{ completedStep: string; resetComplete?: boolean }>()
);

export const resetInfo = createAction(`[${prefix}] resetInfo`);

/**
 * College Actions
 */
export const updateCollegeDetail = createAction(
  `[${prefix}] Update College Detail`,
  props<{ collegeDetail: CollegeDetail }>()
);

export const updateCollegeActivities = createAction(
  `[${prefix}] Update College Activities`,
  props<{
    activities: Activity[];
    activeActivity: Activity;
    collegeId: string;
  }>()
);

export const updateCollegeMediaForActivity = createAction(
  `[${prefix}] Update College Media For Activity`,
  props<{ activity: Activity; collegeId: string }>()
);

export const updateActiveCollege = createAction(
  `[${prefix}] Update Active College`,
  props<{ college: CollegeDetail }>()
);

export const clearEmptyCollege = createAction(
  `[${prefix}] Clear Empty College`
);

export const updateActiveCollegeActivity = createAction(
  `[${prefix}] Update College Active Activity`,
  props<{ activity: Activity; collegeId: string }>()
);

export const updateCollegeMedia = createAction(
  `[${prefix}] Update College Media`,
  props<{ collegeMedia: CollegeMedia }>()
);

export const updateCollegeCompletedStep = createAction(
  `[${prefix}] Update College Completed Step`,
  props<{ completedStep: string; resetComplete?: boolean }>()
);

export const resetCollegeCompletedStep = createAction(
  `[${prefix}] Reset College Completed Step`
);

/**
 * Job Actions
 */
export const updateJobDetail = createAction(
  `[${prefix}] Update Job Detail`,
  props<{ job: JobDetail }>()
);

export const updateOnetTitleJobRecent = createAction(
  `[${prefix}] Update Onet Title Job Recent`,
  props<{ onetTitle: string; jobId: string }>()
);

export const updateJobSkill = createAction(
  `[${prefix}] Update Job Skill`,
  props<{ skills: JobSkill[]; jobId: string }>()
);

export const updateJobMedia = createAction(
  `[${prefix}] Update Job Media`,
  props<{ media: Media; jobId: string }>()
);

export const updateJobLikeMostMedia = createAction(
  `[${prefix}] Update Job Like Most Media`,
  props<{ media: Media; jobId: string }>()
);

export const updateJobAdviceMedia = createAction(
  `[${prefix}] Update Job Advice Media`,
  props<{ media: Media; jobId: string }>()
);

export const updateActiveJob = createAction(
  `[${prefix}] Update active Job`,
  props<{ job: JobDetail }>()
);

export const updateJobCompletedStep = createAction(
  `[${prefix}] Update Job Completed Step`,
  props<{ completedStep: string; resetComplete?: boolean }>()
);

export const resetJobCompletedStep = createAction(
  `[${prefix}] Reset Job Completed Step`
);


export const updateStepperInvalidTimeline = createAction(
  `[${prefix}] Update timeline stepper invalid`,
  props<{
    isDisplay: boolean;
    hasStepInvalid: boolean;
    url: string;
    title: string;
  }>()
);

export const updateDisplayModalInvalidTimeline = createAction(
  `[${prefix}] Update timeline display modal invalid`,
  props<{
    isDisplay: boolean;
  }>()
);

export const updateStepperTimeline = createAction(
  `[${prefix}] Update timeline stepper`,
  props<{ completed?: number[]; active?: number; resetComplete?: boolean }>()
);

export const clearEmptyJob = createAction(`[${prefix}] Clear Empty Job`);

export const fetchMedia = createAction(
  `[${prefix}] Fetch user media list`,
  props<{ media: Media[] }>()
);

export const updateMediaItemInList = createAction(
  `[${prefix}] Update media item in media list`,
  props<{ media: Media }>()
);

export const updateTimelineStatus = createAction(
  `[${prefix}] Update Timeline status`,
  props<{ statusObj: UserStatusInput }>()
);

export const updateTimelineStatusSuccess = createAction(
  `[${prefix}] Update Timeline status success`
);

export const updateApplicantReview = createAction(
  `[${prefix}] Update Applicant Review`,
  props<{ applicantReview:  {
      applicantReviewId: string,
      applicantId: string,
      timeline: any,
      status: string,
    }}>()
);

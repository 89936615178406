<div class="error-page">
  <div className="d-flex" class="img-home">
    <div class="img-top-page">
      <img src="assets/images/logo.png" alt="">
    </div>
  </div>
  <div class="error-message">
    <div class="bubble-r-line">
      <div class="bubble-r-box">Your invitation link has expired. Please contact {{ adminEmail }}.</div>
    </div>
    <div class="btn-back">
      <button (click)="goHome()" type="button" aria-label="Back">Go Home</button>
    </div>
  </div>
</div>

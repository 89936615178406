import { Component } from '@angular/core';

@Component({
  selector: 'vm-loading',
  templateUrl: './loading.component.html',
  styleUrls: [ './loading.component.scss' ],
})

export class LoadingComponent {
}

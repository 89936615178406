import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IAccount } from '../../shared/models';
import { AppState, selectUserAccount } from '../../store/reducer';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.scss' ]
})
export class ErrorComponent implements OnInit, OnDestroy {
  errorMessage?: string;
  errorKey?: string;
  langChangeSubscription?: Subscription;
  adminEmail = '';
  account$: Observable<IAccount>;
  account!: any;
  private completion$ = new Subject();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public store: Store<AppState>,
  ) {
    this.account$ = this.store.select(selectUserAccount);
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.adminEmail = params.adminEmail || 'Admin';
      }
    );
    this.account$.pipe(
      takeUntil(this.completion$),
      filter(data => !!data)
    )
    .subscribe(account => {
      this.account = account;
      if (this.account) {
        this.router.navigate(['/home']);
      }
    });


    this.route.data.subscribe((routeData) => {
      if (routeData.errorMessage) {
        this.errorKey = routeData.errorMessage;
        this.getErrorMessageTranslation();
      }
    });
  }

  goHome() {
    this.router.navigate([ 'home' ]);
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
    this.completion$.next();
    this.completion$.complete();
  }

  private getErrorMessageTranslation(): void {
    this.errorMessage = '';
  }
}

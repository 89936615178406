import { HighSchoolExperience } from './highschool.model';

export class UserTimelineInput {
  highSchoolExperience?: HighSchoolExperience;
  additionalEducationExperience?: any;
  jobExperience?: any;

  constructor(init?: Partial<UserTimelineInput>) {
    if (init) {
      Object.assign(this, init);
    };
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  initVideoAudio,
  IOption,
  MediaType,
  MEDIA_OPTION,
} from '../../constants';
import { deepCopy } from '../../helpers';
import { IFile, Media, Nullable } from '../../models';

@Component({
  selector: 'vm-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent {
  _media: Media;
  get media(): Media {
    return this._media;
  }
  @Input('media') set media(value: Media) {
    this._media = value;
    this.options = deepCopy(MEDIA_OPTION);
    if (
      !value ||
      !value.type ||
      (value.type && (value.content || value.filename))
    ) {
      this.mediaForm.get('text')?.setValue('');
    }
    if (value) {
      this.bindingMedia(value);
    }
  }
  @Output() mediaValue = new EventEmitter<Media>();
  @Output() onDataChange = new EventEmitter<boolean>();

  mediaForm: FormGroup;
  options: IOption[] = deepCopy(MEDIA_OPTION);

  //=======================================
  uploading = false;
  video!: IFile;
  audio!: IFile;
  preActive: IOption = { number: 0, title: '', icon: '', active: false };
  recordVideo!: IFile;
  recordAudio!: IFile;
  mediaType = MediaType;
  audioFileEvent: Nullable<File> = null;
  videoFileEvent: Nullable<File> = null;
  isValueChange: boolean = false;

  constructor(private fb: FormBuilder) {
    this.mediaForm = this.fb.group({
      text: ['', [Validators.maxLength(500)]],
      video: [''],
      audio: [''],
    });
    this._media = new Media();
  }

  bindingMedia(media: Media) {
    const {
      type,
      filename,
      link,
      content,
      isRecording,
      mediaId,
      userId,
      captionGoogle,
      captionUrl,
      captionText,
      captionEmbedded,
      isInvalid,
    } = media;
    if (type) {
      switch (type) {
        case this.mediaType.text: {
          this.options[0].active = true;
          this.mediaForm.get('text')?.setValue(content);
          break;
        }
        case this.mediaType.video: {
          const file = {
            filename,
            url: link,
            type,
            mediaId,
            userId,
            captionGoogle,
            captionUrl,
            captionText,
            captionEmbedded,
            isInvalid,
          };
          if (isRecording) {
            this.recordVideo = file;
            this.video = initVideoAudio(userId, mediaId);
          } else {
            this.video = file;
            this.recordVideo = initVideoAudio(userId, mediaId);
          }
          this.options[1].active = true;
          break;
        }
        case this.mediaType.audio: {
          const file = {
            filename: filename,
            url: link,
            mediaId,
            userId,
            type,
            captionGoogle,
            captionUrl,
            captionText,
            captionEmbedded,
            isInvalid,
          };
          if (isRecording) {
            this.recordAudio = file;
            this.audio = initVideoAudio(userId, mediaId);
          } else {
            this.audio = file;
            this.recordAudio = initVideoAudio(userId, mediaId);
          }
          this.options[2].active = true;
          break;
        }
        default: {
          break;
        }
      }
    } else {
      this.options = deepCopy(MEDIA_OPTION);
      this.mediaForm.get('text')?.setValue('');
      this.resetVideo(userId, mediaId);
    }
  }

  handleChange(e: Event) {
    this.isValueChange = true;
    this.onDataChange.emit(true);
  }

  handleFocusOut(e: Event) {
    if (this.isValueChange) {
      const content = (e.target as HTMLInputElement).value;
      const obj: Media = {
        type: this.mediaType.text,
        link: '',
        filename: '',
        captionGoogle: {},
        captionUrl: '',
        captionText: '',
        captionEmbedded: '',
        isInvalid: false,
        content,
        mediaId: this.media.mediaId,
        userId: this.media.userId,
        isRecording: false,
      };
      this.mediaValue.emit(obj);
    }
    this.isValueChange = false;
  }

  checkOption(option: IOption) {
    if (this.preActive?.number !== option.number && !!this.media) {
      const { mediaId, userId } = this.media;
      this.resetVideo(userId, mediaId);
      const obj: Media = new Media({
        type: option.title.toLowerCase(),
        mediaId,
        userId,
        isRecording: false,
      });
      this.mediaValue.emit(obj);
      this.onDataChange.emit(true);
      this.mediaForm.reset();
    }
    this.preActive = option;
    this.options = this.options.map((opt, index) => ({
      ...opt,
      active: option.number === index + 1,
    }));
  }

  resetVideo(userId: string, mediaId: string) {
    this.video = initVideoAudio(userId, mediaId);
    this.recordVideo = initVideoAudio(userId, mediaId);
    this.audio = initVideoAudio(userId, mediaId);
    this.recordAudio = initVideoAudio(userId, mediaId);
    this.audioFileEvent = null;
    this.videoFileEvent = null;
  }

  handleUploadAndRecord(
    event: IFile & { type: MediaType; isRecording: boolean }
  ) {
    const obj: Media = {
      type: event.type,
      link: event.url,
      filename: event.filename,
      content: '',
      captionGoogle: {},
      captionUrl: '',
      captionText: '',
      captionEmbedded: '',
      isInvalid: false,
      mediaId: this.media.mediaId,
      userId: this.media.userId,
      isRecording: event.isRecording,
    };
    this.mediaValue.emit(obj);
    this.onDataChange.emit(true);
  }

  /**
   * on file drop handler
   */
  onFileDropped(files: File[], type: MediaType) {
    if (files[0]) {
      type === this.mediaType.audio
        ? (this.audioFileEvent = files[0])
        : (this.videoFileEvent = files[0]);
    }
  }

  nullifyDroppedFile() {
    this.audioFileEvent = null;
    this.videoFileEvent = null;
  }
}

import { GenericObject } from '../models';

export const isEmptyObj = (obj: GenericObject) => {
  return Object.values(obj).some(value => !value || (!!value && value.constructor === Object && JSON.stringify(obj) === JSON.stringify({})))
};

export const deepCopy = (obj: any) => {
  const tempObj = JSON.stringify(obj);
  return JSON.parse(tempObj);
};

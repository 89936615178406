import { createAction, props } from '@ngrx/store';
import { Status } from '../../../shared/constants';
import {
  Acknowledgement,
  Funfact,
  PersonalInfo,
  UserReviewInput,
  UserStatusInput,
} from '../../../shared/models';
const prefix = 'Profile';

export const updateProfile = createAction(
  `[${prefix}] Update profile`,
  props<{
    profile: PersonalInfo;
    funfacts: Funfact;
    acknowledgement: Acknowledgement;
    status: UserStatusInput;
    review: UserReviewInput;
  }>()
);

export const updatePersonalInfo = createAction(
  `[${prefix}] Update personal info`,
  props<{ profile: PersonalInfo }>()
);

export const updateFunfacts = createAction(
  `[${prefix}] Update fun facts`,
  props<{ funfacts: Funfact }>()
);

export const updateAcknowledgement = createAction(
  `[${prefix}] Update acknowledgement`,
  props<{ acknowledgement: Acknowledgement }>()
);

export const updateStepper = createAction(
  `[${prefix}] Update profile stepper`,
  props<{ completed?: number[]; active?: number }>()
);

export const resetInfo = createAction(`[${prefix}] resetInfo`);

export const changeTimelineStatus = createAction(
  `[${prefix}] Update Timeline Status`,
  props<{ status: Status }>()
);
